import React, { useContext, useState, useEffect } from "react";
import FontAwesome from "react-fontawesome";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Home = ({ theme }) => {
  //Varijable u kojima se pohranjuje koji je jezik trenutno aktivan (hrvatski/engleski)
  const { t } = useTranslation();
  const [isEnglish, setIsEnglish] = useState();

  //Funkcija za promjenu jezika aplikacije
  function changeLocale(l) {
    i18n.changeLanguage(l);
    localStorage.setItem("i18nextLng", l);
    if (l == "en") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  }

  //Na prvom ucitavanju se postavlja jezik aplikacije
  //Dohvaca se vrijednost iz localStorage
  //Ako korisnik postavi hrvatski jezik, hrvatski jezik ce biti postavljen i pri sljedecem pokretanju aplikacije
  //U slucaju da korisnik prvi put koristi aplikaciju, u localStorage se ne nalazi nista tako da se automatski postavlja default-ni jezik - engleski
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    console.log(lang);
    if (lang == "en") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          opacity: 0,
          y: -100,
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.5,
            duration: 0.5,
          },
        },
      }}
    >
      <section className="home" data-theme={theme}>
        <h1 className="logo">CB</h1>
        <div className="text">
          <h1>{t("where")}</h1>
          <p>{t("route")}</p>
        </div>
        <svg
          className="girl"
          width="485"
          height="407"
          viewBox="0 0 485 407"
          fill="none"
          xmlns="https://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7:48)">
            <path
              d="M71.9194 389.869L81.3285 392.482L95.9537 357.659L82.0669 353.8L71.9194 389.869Z"
              fill="#FFB8B8"
            />
            <path
              d="M97.0327 406.208L67.4601 397.991L70.6545 386.636L89.9449 391.996C92.672 392.754 94.9844 394.557 96.3736 397.009C97.7628 399.461 98.1149 402.361 97.3524 405.071L97.0327 406.208Z"
              fill="#2F2E41"
            />
            <path
              d="M184.161 397.191L193.931 397.191L198.577 359.739L184.158 359.74L184.161 397.191Z"
              fill="#FFB8B8"
            />
            <path
              d="M212.775 406.205L182.068 406.208L182.067 394.418L202.097 394.416C203.499 394.416 204.888 394.69 206.183 395.223C207.479 395.756 208.656 396.538 209.647 397.523C210.639 398.508 211.425 399.678 211.962 400.965C212.498 402.252 212.775 403.632 212.775 405.025L212.775 406.205Z"
              fill="#2F2E41"
            />
            <path
              d="M111.556 241.706L89.0679 296.455L180.895 316.956L182.067 286.325L140.37 242.188L111.556 241.706Z"
              fill="#B3B3B3"
            />
            <path
              d="M161.378 156.418C171.55 150.892 175.289 138.217 169.728 128.108C164.168 117.999 151.414 114.284 141.242 119.81C131.069 125.336 127.331 138.011 132.892 148.12C138.452 158.229 151.206 161.944 161.378 156.418Z"
              fill="#FFB8B8"
            />
            <path
              d="M166.309 238.677L124.744 221.768L121.043 197.518C119.953 190.429 121.655 183.196 125.794 177.323C129.934 171.45 136.192 167.389 143.268 165.985L148.766 164.888C153.282 163.989 157.951 164.2 162.367 165.501C166.783 166.803 170.812 169.156 174.105 172.357C177.331 175.42 179.743 179.229 181.126 183.444C182.509 187.659 182.821 192.149 182.032 196.513C181.411 200.252 179.885 203.785 177.586 206.808C164.528 223.777 166.204 237.884 166.223 238.024L166.309 238.677Z"
              className={"splashBlue"}
            />
            <path
              d="M183.233 301.917L162.403 268.995L168.964 209.107L159.9 164.317L160.382 164.329C165.654 164.458 170.673 166.598 174.401 170.304C178.129 174.01 180.279 179 180.407 184.238L189.224 228.128L183.233 301.917Z"
              fill="#2F2E41"
            />
            <path
              d="M183.709 390.111C182.831 390.111 181.98 389.808 181.301 389.255C180.622 388.702 180.157 387.932 179.984 387.077L170.858 341.978C169.191 333.716 166.052 325.816 161.589 318.65L135.838 277.253C135.71 277.048 135.527 276.881 135.31 276.771C135.092 276.662 134.849 276.614 134.606 276.634C134.363 276.653 134.13 276.738 133.933 276.881C133.736 277.023 133.582 277.217 133.488 277.44L91.152 378.512C90.8117 379.32 90.2002 379.985 89.4215 380.396C88.6428 380.806 87.7452 380.935 86.8812 380.762L75.5716 378.471C74.6027 378.278 73.7477 377.717 73.1891 376.908C72.6306 376.098 72.4126 375.103 72.5818 374.135L81.808 320.1C83.69 309.086 86.8968 298.337 91.3595 288.083L122.605 216.318L168.717 231.368L171.759 263.456L193.065 314.726C194.537 318.266 195.418 322.022 195.67 325.845L199.607 385.099C199.668 386.088 199.338 387.061 198.687 387.812C198.035 388.562 197.115 389.03 196.122 389.115L184.022 390.099C183.917 390.107 183.813 390.111 183.709 390.111Z"
              fill="#2F2E41"
            />
            <path
              d="M99.5907 316.6L84.9722 302.81L112.599 196.939C114.352 190.282 117.638 184.122 122.199 178.945C126.759 173.767 132.468 169.715 138.875 167.107L139.418 166.887L137.941 231.323L99.5907 316.6Z"
              fill="#2F2E41"
            />
            <path
              d="M146.419 158.9C141.233 154.439 139.656 146.117 142.833 139.983C146.011 133.848 153.609 130.545 160.105 132.475C166.224 134.292 173.18 128.23 172.435 121.73C171.689 115.229 163.552 110.995 158.012 114.224C153.817 112.551 149.224 112.117 144.786 112.976C140.349 113.835 136.255 115.95 132.998 119.066C126.489 125.402 124.02 135.762 126.942 144.473C129.864 153.183 138.021 159.778 146.419 158.9Z"
              fill="#2F2E41"
            />
            <path
              d="M128.056 328.438C127.8 328.536 127.534 328.604 127.262 328.642L93.8868 333.272C93.0794 333.387 92.2568 333.225 91.5541 332.813C90.8515 332.402 90.3107 331.765 90.0208 331.007L61.2929 256.719C61.1069 256.238 61.0274 255.723 61.0598 255.209C61.0922 254.695 61.2358 254.193 61.4808 253.739C61.7258 253.285 62.0664 252.889 62.4794 252.577C62.8924 252.266 63.3682 252.047 63.8742 251.934L63.8744 251.934L95.2599 244.974C95.5022 244.92 95.7364 244.835 95.9566 244.721L126.483 228.859C126.719 228.737 126.968 228.64 127.226 228.572L160.821 219.672C161.681 219.442 162.596 219.538 163.39 219.94C164.184 220.342 164.799 221.023 165.117 221.85L190.166 286.624C190.386 287.188 190.458 287.799 190.375 288.399C190.292 288.998 190.057 289.567 189.692 290.051L181.029 301.612C180.622 302.156 180.067 302.574 179.43 302.817L160.498 310.048C160.362 310.1 160.231 310.162 160.104 310.234L128.557 328.202C128.396 328.293 128.229 328.372 128.056 328.438Z"
              fill="white"
            />
            <path
              d="M143.584 229.575L139.318 230.706L128.013 233.702L127.992 233.707L112.515 241.749L106.771 244.735L106.768 244.736L98.1411 249.218L97.9046 249.271L88.9427 251.257L84.6115 252.219L66.9458 256.137L94.6787 327.852L112.883 325.327L117.347 324.707L126.86 323.386L126.971 323.372L157.542 305.959L176.142 298.855L184.634 287.522L160.493 225.096L143.584 229.575Z"
              fill="#E6E6E6"
            />
            <path
              d="M164.471 235.381L72.5024 270.505L74.0774 274.578L166.046 239.453L164.471 235.381Z"
              fill="white"
            />
            <path
              d="M169.72 248.956L77.752 284.081L79.3269 288.154L171.295 253.029L169.72 248.956Z"
              fill="white"
            />
            <path
              d="M180.325 276.379L88.3564 311.504L89.9314 315.577L181.9 280.452L180.325 276.379Z"
              fill="white"
            />
            <path
              d="M143.584 229.575L171.118 300.774L167.02 302.339L139.318 230.706L143.584 229.575Z"
              fill="white"
            />
            <path
              d="M112.515 241.75L106.861 257.383L104.901 262.806L100.328 275.459L99.3478 278.164L98.3672 280.882L97.0759 284.451L87.8075 310.084L85.3979 303.853L92.9551 282.949L94.6648 278.217L94.9155 277.526L96.9374 271.93L99.4885 264.873L101.449 259.45L106.768 244.736L106.77 244.735L112.515 241.75Z"
              fill="white"
            />
            <path
              opacity="0.1"
              d="M158.208 305.705L157.541 305.959L126.971 323.372L126.86 323.386L97.9043 249.271L98.1412 249.218L106.768 244.736L106.77 244.735L112.515 241.75L127.993 233.707L128.013 233.702L158.208 305.705Z"
              fill="black"
            />
            <path
              d="M128.194 328.795C127.91 328.903 127.615 328.979 127.315 329.021L93.9397 333.651C93.0462 333.778 92.1359 333.599 91.3583 333.143C90.5808 332.688 89.9824 331.983 89.6615 331.145L60.9337 256.857C60.7278 256.324 60.6399 255.754 60.6758 255.185C60.7117 254.616 60.8706 254.061 61.1417 253.559C61.4128 253.056 61.7897 252.618 62.2468 252.273C62.7038 251.928 63.2302 251.686 63.7902 251.561L95.1758 244.601C95.3852 244.554 95.5877 244.48 95.7781 244.382L126.305 228.521C126.566 228.385 126.841 228.278 127.126 228.203L160.721 219.303C161.674 219.049 162.687 219.154 163.565 219.6C164.443 220.045 165.124 220.798 165.476 221.713L190.525 286.487C190.768 287.112 190.848 287.787 190.756 288.451C190.664 289.114 190.404 289.744 190 290.28L181.337 301.841C180.887 302.443 180.273 302.905 179.568 303.174L160.636 310.405C160.518 310.45 160.404 310.503 160.295 310.565L128.748 328.534C128.57 328.635 128.385 328.722 128.194 328.795ZM63.779 253.157C63.1718 253.39 62.6822 253.852 62.4179 254.443C62.1536 255.034 62.1362 255.705 62.3695 256.308L91.0973 330.596C91.2945 331.112 91.6624 331.545 92.1403 331.825C92.6183 332.105 93.1779 332.215 93.7271 332.137L127.102 327.507C127.412 327.463 127.711 327.362 127.983 327.207L159.531 309.239C159.708 309.138 159.893 309.051 160.084 308.978L179.016 301.747C179.449 301.582 179.827 301.298 180.104 300.928L188.767 289.367C189.015 289.037 189.175 288.65 189.231 288.243C189.288 287.835 189.239 287.419 189.089 287.036L164.04 222.261C163.824 221.698 163.405 221.236 162.865 220.962C162.326 220.688 161.703 220.623 161.118 220.779L127.522 229.68C127.348 229.727 127.179 229.792 127.018 229.875L96.4908 245.737C96.1811 245.897 95.8518 246.017 95.5112 246.093L64.1254 253.053C64.0075 253.08 63.8917 253.114 63.779 253.157Z"
              fill="#E6E6E6"
            />
            <path
              d="M88.9429 251.257L117.346 324.707L112.883 325.327L84.6113 252.219L88.9429 251.257Z"
              fill="white"
            />
            <path
              d="M97.1118 280.905C98.2434 280.905 99.1607 279.993 99.1607 278.868C99.1607 277.744 98.2434 276.832 97.1118 276.832C95.9803 276.832 95.063 277.744 95.063 278.868C95.063 279.993 95.9803 280.905 97.1118 280.905Z"
              fill="#3F3D56"
            />
            <path
              d="M169.757 283.075C170.888 283.075 171.806 282.163 171.806 281.039C171.806 279.914 170.888 279.003 169.757 279.003C168.625 279.003 167.708 279.914 167.708 281.039C167.708 282.163 168.625 283.075 169.757 283.075Z"
              className={"splashBlue"}
            />
            <path
              d="M170.586 244.263C172.912 250.276 170.113 270.203 169.129 276.6C169.098 276.803 169.014 276.994 168.884 277.154C168.755 277.314 168.585 277.437 168.392 277.51C168.2 277.584 167.991 277.605 167.787 277.573C167.583 277.54 167.391 277.455 167.232 277.325C162.19 273.229 146.683 260.293 144.358 254.28C143.696 252.569 143.38 250.744 143.428 248.911C143.475 247.079 143.885 245.273 144.635 243.598C145.385 241.922 146.459 240.41 147.797 239.148C149.135 237.885 150.71 236.897 152.432 236.239C154.154 235.581 155.99 235.267 157.834 235.314C159.679 235.361 161.496 235.769 163.181 236.514C164.867 237.26 166.389 238.327 167.659 239.657C168.93 240.986 169.924 242.551 170.586 244.263V244.263Z"
              className={"splashBlue"}
            />
            <path
              d="M157.472 256.834C161.675 256.834 165.082 253.448 165.082 249.272C165.082 245.095 161.675 241.709 157.472 241.709C153.269 241.709 149.862 245.095 149.862 249.272C149.862 253.448 153.269 256.834 157.472 256.834Z"
              fill="white"
            />
            <path
              d="M147.697 232.953C148.005 231.83 148.557 230.787 149.315 229.899C150.072 229.011 151.016 228.298 152.08 227.812C153.145 227.325 154.303 227.076 155.475 227.083C156.647 227.09 157.802 227.352 158.861 227.85L172.446 215.646L181.923 222.042L162.494 239.008C161.533 240.72 159.974 242.023 158.112 242.669C156.249 243.314 154.213 243.259 152.389 242.512C150.565 241.766 149.08 240.381 148.216 238.619C147.351 236.857 147.166 234.841 147.697 232.953Z"
              fill="#FFB8B8"
            />
            <path
              d="M169.587 236.937L158.938 222.574L183.215 207.133L165.814 182.458C164.393 180.442 163.769 177.975 164.064 175.531C164.358 173.086 165.55 170.836 167.41 169.21C169.271 167.584 171.669 166.698 174.146 166.721C176.624 166.743 179.005 167.674 180.835 169.333L202.623 189.088C204.698 190.969 206.308 193.302 207.328 195.903C208.347 198.505 208.749 201.305 208.501 204.086C208.253 206.867 207.363 209.553 205.899 211.936C204.435 214.319 202.438 216.334 200.062 217.824L169.587 236.937Z"
              fill="#2F2E41"
            />
            <path
              d="M86.1548 274.621C87.1174 273.957 87.922 273.092 88.5117 272.086C89.1015 271.08 89.4621 269.957 89.5682 268.798C89.6743 267.638 89.5232 266.47 89.1257 265.375C88.7282 264.279 88.0939 263.284 87.2675 262.459L94.307 245.658L85.0766 238.916L75.4708 262.781C74.1678 264.253 73.4532 266.148 73.4625 268.109C73.4718 270.069 74.2043 271.958 75.5212 273.417C76.8382 274.876 78.6481 275.805 80.6079 276.027C82.5677 276.249 84.5414 275.748 86.1548 274.621V274.621Z"
              fill="#FFB8B8"
            />
            <path
              d="M90.9108 257.561L74.2627 253.708L86.6688 218.156C88.8067 212.022 91.9608 206.285 96.0007 201.184L116.24 175.606C118.091 173.267 120.8 171.752 123.773 171.393C126.746 171.034 129.741 171.861 132.101 173.692C134.462 175.523 135.996 178.21 136.368 181.163C136.739 184.116 135.918 187.095 134.083 189.448L111.625 218.254L90.9108 257.561Z"
              fill="#2F2E41"
            />
            <path
              d="M293.794 407H0.769095C0.565118 407 0.369483 406.92 0.22525 406.776C0.0810171 406.633 0 406.438 0 406.236C0 406.033 0.0810171 405.839 0.22525 405.695C0.369483 405.552 0.565118 405.471 0.769095 405.471H293.794C293.998 405.471 294.194 405.552 294.338 405.695C294.482 405.839 294.563 406.033 294.563 406.236C294.563 406.438 294.482 406.633 294.338 406.776C294.194 406.92 293.998 407 293.794 407Z"
              fill="#CCCCCC"
            />
            <path
              d="M407.457 406.618H400.535V3.43944C400.535 2.52724 400.899 1.65241 401.548 1.00739C402.197 0.362371 403.078 0 403.996 0C404.914 0 405.794 0.362371 406.443 1.00739C407.092 1.65241 407.457 2.52724 407.457 3.43944V406.618Z"
              fill="#E6E6E6"
            />
            <path
              d="M457.611 407H349.938C349.734 407 349.538 406.92 349.394 406.776C349.25 406.633 349.169 406.438 349.169 406.236C349.169 406.033 349.25 405.839 349.394 405.695C349.538 405.552 349.734 405.471 349.938 405.471H457.611C457.815 405.471 458.011 405.552 458.155 405.695C458.299 405.839 458.38 406.033 458.38 406.236C458.38 406.438 458.299 406.633 458.155 406.776C458.011 406.92 457.815 407 457.611 407Z"
              fill="#CCCCCC"
            />
            <path
              d="M455.486 52.738H354.773C353.856 52.737 352.976 52.3743 352.327 51.7295C351.678 51.0847 351.314 50.2104 351.312 49.2986V20.2544C351.314 19.3426 351.678 18.4683 352.327 17.8235C352.976 17.1787 353.856 16.816 354.773 16.815H455.494C456.084 16.8154 456.664 16.9656 457.18 17.2513L483.225 31.6961C483.762 31.9938 484.21 32.4283 484.521 32.9548C484.833 33.4813 484.998 34.0809 485 34.6918C485.001 35.3028 484.839 35.9031 484.529 36.431C484.22 36.9588 483.774 37.3953 483.239 37.6953L457.186 52.2946C456.667 52.5849 456.082 52.7376 455.486 52.738Z"
              className={"splashBlue"}
            />
            <path
              d="M446.296 115.412H345.583C344.988 115.412 344.402 115.259 343.884 114.969L317.831 100.37C317.295 100.07 316.85 99.6331 316.54 99.1052C316.231 98.5773 316.068 97.9771 316.07 97.3661C316.071 96.7551 316.236 96.1555 316.548 95.629C316.86 95.1025 317.307 94.6681 317.844 94.3703L343.889 79.9255C344.405 79.6398 344.986 79.4897 345.576 79.4893H446.296C447.214 79.4903 448.093 79.853 448.742 80.4978C449.391 81.1426 449.756 82.0168 449.757 82.9287V111.973C449.756 112.885 449.391 113.759 448.742 114.404C448.093 115.049 447.214 115.411 446.296 115.412Z"
              fill="#E6E6E6"
            />
            <path
              d="M446.681 176.94H345.968C345.372 176.939 344.787 176.787 344.268 176.496L318.215 161.897C317.68 161.597 317.234 161.161 316.925 160.633C316.615 160.105 316.453 159.505 316.454 158.894C316.455 158.283 316.621 157.683 316.932 157.157C317.244 156.63 317.692 156.196 318.229 155.898L344.274 141.453C344.789 141.167 345.37 141.017 345.96 141.017H446.681C447.598 141.018 448.478 141.381 449.127 142.025C449.775 142.67 450.14 143.544 450.141 144.456V173.5C450.14 174.412 449.775 175.287 449.127 175.931C448.478 176.576 447.598 176.939 446.681 176.94Z"
              fill="#E6E6E6"
            />
          </g>
          <defs>
            <clipPath id="clip0_7:48">
              <rect width="485" height="407" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div className="buttons">
          <a className="findBus" href="/FindBus">
            <FontAwesome
              className="compass"
              name="compass"
              style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
            />
            {t("findBus")}
          </a>
          <a className="needHelp" href="/Help">
            <FontAwesome
              className="question"
              name="question-circle"
              style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
            />
            {t("needHelp")}
          </a>
          <div className="language">
            <div className={"language-icon"}>
              <svg
                width={"24"}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM22 12C22 12.685 21.93 13.354 21.798 14H17.945C18.066 12.717 18.074 11.379 17.945 10H21.798C21.93 10.646 22 11.315 22 12ZM21.159 8H17.659C17.276 6.04 16.607 4.249 15.711 2.722C18.146 3.699 20.108 5.604 21.159 8ZM15.605 8H13V2.342C14.215 3.802 15.117 5.752 15.605 8ZM11 2.342V8H8.395C8.883 5.752 9.785 3.802 11 2.342ZM11 10V14H8.07C7.924 12.579 7.924 11.423 8.07 10H11ZM11 16V21.658C9.785 20.198 8.883 18.248 8.395 16H11ZM13 21.658V16H15.605C15.117 18.248 14.215 20.198 13 21.658ZM13 14V10H15.93C16.076 11.421 16.076 12.577 15.93 14H13ZM8.289 2.722C7.393 4.249 6.724 6.04 6.341 8H2.841C3.892 5.604 5.854 3.699 8.289 2.722ZM2.202 10H6.055C5.934 11.283 5.926 12.621 6.055 14H2.202C2.07 13.354 2 12.685 2 12C2 11.315 2.07 10.646 2.202 10ZM2.841 16H6.341C6.724 17.96 7.393 19.751 8.289 21.278C5.854 20.301 3.892 18.396 2.841 16ZM15.711 21.278C16.607 19.751 17.276 17.96 17.659 16H21.159C20.108 18.396 18.146 20.301 15.711 21.278Z"
                  className={"splashBlue"}
                />
              </svg>
            </div>
            <a
              href="#0"
              onClick={() => {
                changeLocale("en");
              }}
              className={isEnglish ? "main-gradient" : "grey-text"}
            >
              en
            </a>
            <p>|</p>
            <a
              href="#0"
              onClick={() => {
                changeLocale("hr");
              }}
              className={!isEnglish ? "main-gradient" : "grey-text"}
            >
              hr
            </a>
          </div>
          <p>
            {t("feedback")}
            <a href="/Contact">feedback</a>
          </p>
        </div>
        <div className="white-background">
          <svg
            className="eclipse"
            viewBox="0 0 568 348"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <ellipse cx="284" cy="174" rx="284" ry="174" className={"bg"} />
          </svg>
        </div>
      </section>
    </motion.div>
  );
};

export default Home;
