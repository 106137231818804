import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBkSkltB3lXiM-ZNSL2CIIUsISM-eYp0p8",
  authDomain: "catchbus-8a4d0.firebaseapp.com",
  projectId: "catchbus-8a4d0",
  storageBucket: "catchbus-8a4d0.appspot.com",
  messagingSenderId: "698901669858",
  appId: "1:698901669858:web:15f509f1ae03845796fbbb",
  measurementId: "G-YFPLS9H6H1"
};

/*const firebaseConfig = {
  apiKey: "AIzaSyDcO0XZTbcX7503cVPJeb0CdjifUFTVBOE",
  authDomain: "fir-test-b7c87.firebaseapp.com",
  projectId: "fir-test-b7c87",
  storageBucket: "fir-test-b7c87.appspot.com",
  messagingSenderId: "462334573621",
  appId: "1:462334573621:web:fe33f9362e7b57813cc57c",
  measurementId: "G-L5BETCRPDJ"
};*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db