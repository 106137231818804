import arrowBack from "../assets/img/arrowBack.svg";
import time from "../assets/img/time-icon.svg";
import purpleBus from "../assets/img/purple-bus.svg";
import ruler from "../assets/img/ruler.svg";
import change from "../assets/img/change.svg";
import { useEffect, useState } from "react";
const UpLocate = ({
  pocetnaLokacija,
  odredisnaLokacija,
  setPocetnaLokacija,
  setOdredisnaLokacija,
  inputPocetnaAdresa,
  inputOdredisnaAdresa,
  setInputPocetnaAdresa,
  setInputOdredisnaAdresa,
  duzinaPuta,
  odgovarajuciBusevi,
  theme,
  setIsDashboardOpen,
  setIsFirstInputActive,
}) => {
  //Komponenta koja se nalazi u gornjem dijelu nakon odabira lokacija

  const [duzinaPutaConverted, setDuzinaPutaConverted] = useState("");
  const [vrijemeCekanja, setVrijemeCekanja] = useState("");

  useEffect(() => {
    const converter = duzinaPuta * 0.001;
    const rounded = converter.toFixed(1);
    setDuzinaPutaConverted(rounded);
  }, [duzinaPuta]);

  useEffect(() => {
    const brojBuseva = odgovarajuciBusevi.length;

    if (brojBuseva <= 2) {
      setVrijemeCekanja("10");
    } else if (brojBuseva <= 4) {
      setVrijemeCekanja("5");
    } else {
      setVrijemeCekanja("2");
    }
  }, [odgovarajuciBusevi]);

  const zamjeniLokacije = () => {
    const first = {
      pocetnaLokacija: pocetnaLokacija,
      inputPocetnaAdresa: inputPocetnaAdresa,
    };
    setPocetnaLokacija(odredisnaLokacija);
    setInputPocetnaAdresa(inputOdredisnaAdresa);
    setOdredisnaLokacija(first.pocetnaLokacija);
    setInputOdredisnaAdresa(first.inputPocetnaAdresa);
  };

  return (
    <div>
      <header className="header-dashboard header-uplocate" data-theme={theme}>
        <div
          className="arrowBack"
          onClick={() => {
            setIsDashboardOpen(true);
            setIsFirstInputActive(true);
          }}
          style={{ padding: "20px" }}
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              d="M7.77396 16L9.79058 13.9433L5.41592 9.45164L17 9.45164V6.54836L5.41592 6.54836L9.79058 2.05673L7.77396 0L0 8L7.77396 16Z"
              fill="#626262"
            />
          </svg>
        </div>
        <div className="dash-container">
          <div className="circle-square upLocate-cs">
            <div className="square"></div>
            <div className="line"></div>
            <div className="box"></div>
          </div>
          <div className="allWrapper">
            <div className="dashInputs locateInputs">
              <input
                onClick={() => {
                  setIsDashboardOpen(true);
                  setIsFirstInputActive(true);
                }}
                readOnly
                value={inputPocetnaAdresa}
                type="text"
                placeholder="Where are you"
              />
              <div className="switcherLoc">
                <input
                  onClick={() => {
                    setIsDashboardOpen(true);
                    setIsFirstInputActive(true);
                  }}
                  readOnly
                  value={inputOdredisnaAdresa}
                  type="text"
                  placeholder="Where are you going?"
                />

                <svg
                  style={{ margin: "10px" }}
                  onClick={zamjeniLokacije}
                  width="13"
                  height="15"
                  viewBox="0 0 13 15"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 3.65833L1.02836 4.60733L3.27418 2.54867L3.27418 8H4.72582V2.54867L6.97164 4.60733L8 3.65833L4 0L0 3.65833Z"
                    fill="#626262"
                  />
                  <path
                    d="M13 11.3417L11.9716 10.3927L9.72582 12.4513V7H8.27418V12.4513L6.02836 10.3927L5 11.3417L9 15L13 11.3417Z"
                    fill="#626262"
                  />
                </svg>
              </div>
            </div>
            <div className="bus-information">
              <div className="time info-spec">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0277 3.85613C10.5466 3.237 10.8332 2.46025 10.8332 1.66996V0H2.1665V1.66996C2.1665 2.46025 2.4525 3.23646 2.97196 3.85558L4.64355 5.84892C4.95067 6.21563 4.95284 6.72913 4.64842 7.098L2.953 9.15308C2.446 9.76842 2.1665 10.537 2.1665 11.317V13H10.8332V11.317C10.8332 10.537 10.5531 9.76896 10.0461 9.15363L8.35071 7.09854C8.0463 6.72967 8.04846 6.21617 8.35559 5.84946L10.0277 3.85613ZM7.51492 7.787L9.21034 9.84208C9.5635 10.2711 9.74984 10.777 9.74984 11.375H3.24984C3.24984 10.777 3.43563 10.2705 3.78934 9.84262L5.48421 7.78808C6.11634 7.02217 6.112 5.91446 5.47392 5.15342L3.80234 3.15954C3.44484 2.73433 3.24984 2.21921 3.24984 1.62446H9.74984C9.74984 2.22083 9.55321 2.73488 9.19734 3.15954L7.52521 5.15287C6.88767 5.91392 6.88334 7.02217 7.51492 7.787Z"
                    className={"splashBlue"}
                  />
                </svg>
                <p>{vrijemeCekanja} min</p>
              </div>
              <div className="busNumber info-spec">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 6.7225C9.7295 6.823 9.0065 7 7.264 7C5.5105 7 4.777 6.821 4.5 6.7185V3.5H10V6.7225ZM10.25 3H4.25C4.112 3 4 3.112 4 3.25V7C4 7 4.3125 7.5 7.264 7.5C10.2155 7.5 10.5 7 10.5 7V3.25C10.5 3.112 10.388 3 10.25 3ZM8.5 8.75C8.5 8.888 8.388 9 8.25 9H6.25C6.112 9 6 8.888 6 8.75C6 8.6115 6.112 8.5 6.25 8.5H8.25C8.388 8.5 8.5 8.6115 8.5 8.75ZM9.75 9C9.6125 9 9.5 8.8875 9.5 8.75C9.5 8.612 9.6125 8.5 9.75 8.5C9.8875 8.5 10 8.612 10 8.75C10 8.8875 9.8875 9 9.75 9ZM9.75 8C9.336 8 9 8.336 9 8.75C9 9.164 9.336 9.5 9.75 9.5C10.164 9.5 10.5 9.164 10.5 8.75C10.5 8.336 10.164 8 9.75 8ZM4.75 9C4.612 9 4.5 8.8875 4.5 8.75C4.5 8.612 4.612 8.5 4.75 8.5C4.888 8.5 5 8.612 5 8.75C5 8.8875 4.888 9 4.75 9ZM4.75 8C4.336 8 4 8.336 4 8.75C4 9.164 4.336 9.5 4.75 9.5C5.164 9.5 5.5 9.164 5.5 8.75C5.5 8.336 5.164 8 4.75 8ZM11.5 6H11V9.5C11 9.8105 10.74 10 10.5 10H4C3.6915 10 3.5 9.742 3.5 9.5V6H3V4.5H3.5V3C3.5 2.4485 3.9485 2 4.5 2H10C10.5515 2 11 2.4485 11 3V4.5H11.5V6ZM10 11H9.5V10.5H10V11ZM5 11H4.5V10.5H5V11ZM11.5 4V3C11.5 2.1715 10.8285 1.5 10 1.5H4.5C3.6715 1.5 3 2.1715 3 3V4C2.724 4 2.5 4.224 2.5 4.5V6C2.5 6.2755 2.724 6.5 3 6.5V9.5C3 10.1195 3.5185 10.5 4 10.5V11C4 11.2755 4.224 11.5 4.5 11.5H5C5.276 11.5 5.5 11.2755 5.5 11V10.5H9V11C9 11.2755 9.224 11.5 9.5 11.5H10C10.276 11.5 10.5 11.2755 10.5 11V10.5C10.979 10.5 11.5 10.121 11.5 9.5V6.5C11.776 6.5 12 6.2755 12 6V4.5C12 4.224 11.776 4 11.5 4ZM0.5 1H2V3H0.5V1ZM2 3.5C2.276 3.5 2.5 3.2755 2.5 3V1C2.5 0.724 2.276 0.5 2 0.5H0.5C0.224 0.5 0 0.724 0 1V3C0 3.2755 0.224 3.5 0.5 3.5H1V11H0V11.5H2.5V11H1.5V3.5H2Z"
                    className={"splashBlue"}
                  />
                </svg>
                <p>
                  {odgovarajuciBusevi.length}{" "}
                  {odgovarajuciBusevi.length > 1 ? "buses" : "bus"}
                </p>
              </div>
              <div className="kilmeters info-spec">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.8205 11.2845L0 12L0.6985 9.163L2.8205 11.2845ZM1.406 8.456L3.5275 10.5775L12 2.121L9.879 0L1.406 8.456ZM8.463 7.0645L7.756 7.7715L8.11 8.1255L8.817 7.4185L9.524 8.1255L8.463 9.1865L8.8165 9.54L9.8775 8.479L10.586 9.1715L9.1715 10.586L7.0555 8.4695L6.348 9.176L9.172 12L12 9.1715L9.1785 6.35L8.463 7.0645ZM3.5195 4.934L1.4145 2.829L2.8285 1.4145L3.513 2.115L2.4525 3.1755L2.806 3.529L3.8665 2.468L4.5735 3.1755L3.8665 3.8825L4.22 4.236L4.927 3.529L5.643 2.8145L2.8285 0L0 2.8285L2.8115 5.6405L3.5195 4.934Z"
                    className={"splashBlue"}
                  />
                </svg>
                <p>{duzinaPutaConverted} km</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default UpLocate;
