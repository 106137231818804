import React, { useEffect, useRef, useState } from "react";
// CatchBus backend
import ReactMapGL, { Marker, GeolocateControl, StaticMap } from "react-map-gl";
import DeckGL from '@deck.gl/react';
import {IconLayer, PathLayer} from '@deck.gl/layers'
import PolylineOverlay from "../components/PolylineOverlay";
import RenderMarkers from "../components/RenderMarkers";
/* import icon from "../assets/img/gps-icon.svg" */
import icon from "../assets/img/gps-icon.png"
import darkIcon from "../assets/img/gps-icon-dark.png"
import lokacijaKorisnikaIcon from "../assets/img/user-location.png"
import { handle } from "express/lib/router";
import mapboxgl from 'mapbox-gl';

    // The following is required to stop "npm build" from transpiling mapbox code.
    // notice the exclamation point in the import.
    // @ts-ignore
    // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
const Map = ({
  stanice,
  pronadeniBus,
  pocetnaLokacija,
  odredisnaLokacija,
  lokacijaKorisnika,
  stanicaA,
  stanicaB,
  viewport,
  theme,
  setDuzinaPuta,
  drivingLayer,
  setDrivingLayer,
  walkingLayer,
  setWalkingLayer,
  locationsLayer,
  setLocationsLayer
}) => {

  //Varijable koje sadrze podatke vezano za prikaz mape
  //map_key - pristupni token (access token) koji povezuje mapu s CatchBus korisnickim racunom na Mapbox GL platformi
  //map_style - izgled mape (tamni, svijetli prikaz koji se mijenja u postavkama aplikacije)
  const map_key = "pk.eyJ1IjoidGFyaWtiZXNpYyIsImEiOiJja3Znd2JzYjkxZ3E3MndxNWxpNHhncjFpIn0.doc_BQK1Yz9aVzRKbVNxEw";
  const [map_style, setMap_style] = useState("mapbox://styles/mapbox/light-v10");
  const [markersLayer, setMarkersLayer] = useState()
  const [navigationLayer, setNavigationLayer] = useState([])
  const [drivingData, setDrivingData] = useState([])
  const [walkingData, setWalkingData] = useState([])


  //U trenutku promjene varijable 'theme' u postavkama koja predstavlja svijetli i tamni nacin rada (Light/Dark mode) se mijenja i izgled mape
  useEffect(() => {
    if (theme == 'dark') {
      setMap_style("mapbox://styles/mapbox/dark-v10")
    }
  }, [theme]);

  const array = useRef([])
    
  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 94, height: 94, anchorY: 70, mask: false}
  };

  useEffect(() => {
    array.current=[]
    stanice.map(stanica=>{
      array.current = [...array.current, {name: stanica.ime, position: [stanica.lokacija.longitude, stanica.lokacija.latitude]}]
    })

    let iconColor;
    if(theme === "light"){
      iconColor = icon;
    } else {
      iconColor = darkIcon
    }
console.log(iconColor)
    const layer = new IconLayer({

      id: 'icon-layer',
      data: array.current,
      pickable: false,
      iconAtlas: iconColor,
      iconMapping: ICON_MAPPING,
      getIcon: d => 'marker',
      getColor: d => [181, 181, 181],
      sizeScale: 6,
      getPosition: d => d.position,
      getSize: d => 5,
    });
    setMarkersLayer(layer)
  }, [stanice, theme])
  
  useEffect(() => {
    const layer = new PathLayer({
      id: 'path-layer-driving',
      data: drivingData,
      getColor: d => [181, 181, 181],
      getPath: d => d.path,
      getWidth: d => 3,
      widthScale: 5,
      widthMinPixels: 4,
      widthMaxPixels: 10,
      jointRounded: true,
      parameters: {
        depthMask: false
      },
      pickable: true,
    });
    setDrivingLayer([layer])
  }, [drivingData])

  useEffect(() => {
    const layer = new PathLayer({
      id: 'path-layer-walking',
      data: walkingData,
      getColor: d => [250, 174, 55],
      getPath: d => d.path,
      getWidth: d => 3,
      widthScale: 5,
      widthMinPixels: 4,
      widthMaxPixels: 10,
      jointRounded: true,
      parameters: {
        depthMask: false
      },
      pickable: true,
    });
    setWalkingLayer([layer])
  }, [walkingData])
  

  //Funkcija u kojoj se ruta autobusa razdvaja u tri dijela ako autobus prolazi dijelom kojim automobil ne smije voziti
  const handleCustom = (prviBreak, drugiBreak, potrebneStanice) => {

    const indexPrviBreak = potrebneStanice.indexOf(prviBreak)
    const indexDrugiBreak = potrebneStanice.indexOf(drugiBreak)

    const potrebneStanice1 = potrebneStanice.slice(0, indexPrviBreak + 1)
    const potrebneStanice2 = potrebneStanice.slice(indexPrviBreak, indexDrugiBreak + 1)
    const potrebneStanice3 = potrebneStanice.slice(indexDrugiBreak)

    const bus_stations1 = potrebneStanice1.map(
      (stanica) => stanice.find(({ ime }) => stanica === ime)?.lokacija
    );
    const coords1 = bus_stations1
      .map((station) => station.longitude + "," + station.latitude)
      .join(";");

    const bus_stations2 = potrebneStanice2.map(
      (stanica) => stanice.find(({ ime }) => stanica === ime)?.lokacija
    );
    const coords2 = bus_stations2
      .map((station) => station.longitude + "," + station.latitude)
      .join(";");

    const bus_stations3 = potrebneStanice3.map(
      (stanica) => stanice.find(({ ime }) => stanica === ime)?.lokacija
    );
    const coords3 = bus_stations3
      .map((station) => station.longitude + "," + station.latitude)
      .join(";");

    (async () => {
      let prvaRuta, drugaRuta, trecaRuta;
      if(coords1.split(';').length>=2)
      prvaRuta = await matchRoute(coords1);
      if(coords2.split(';').length>=2)
      drugaRuta = await matchWalking(coords2);
      if(coords3.split(';').length>=2)
      trecaRuta = await matchRoute(coords3);

      if (prvaRuta) {
        if (trecaRuta) {
          const finalRoute = [...prvaRuta.geometry.coordinates, ...drugaRuta.geometry.coordinates, ...trecaRuta.geometry.coordinates]
          setDrivingData([{
            "name": "driving",
            "color": "#ed1c24",
            "path": finalRoute
          }])
          setDuzinaPuta(prvaRuta.distance + drugaRuta.distance + trecaRuta.distance)
        } else {
          const finalRoute = [...prvaRuta.geometry.coordinates, ...drugaRuta.geometry.coordinates]
          setDrivingData([{
            "name": "driving",
            "color": "#ed1c24",
            "path": finalRoute
          }])
          setDuzinaPuta(prvaRuta.distance + drugaRuta.distance)
        }
      } else {
        const finalRoute = [...drugaRuta.geometry.coordinates, ...trecaRuta.geometry.coordinates]
        setDrivingData([{
          "name": "driving",
          "color": "#ed1c24",
          "path": finalRoute
        }])
        setDuzinaPuta(drugaRuta.distance + trecaRuta.distance)
      }
    })()
  }

  //Funkcija u kojoj se formatira niz stanica kojima prolazi `pronadeniBus` od pocetne stanice do odredisne stanice
  const handleNormal = (potrebneStanice) => {
    const bus_stations = potrebneStanice.map(
      (stanica) => stanice.find(({ ime }) => stanica === ime)?.lokacija
    );
    const coords = bus_stations
      .map((station) => station.longitude + "," + station.latitude)
      .join(";");
    
    //Postavljanje API requesta
    (async () => {
      const finalRoute = await matchRoute(coords);

      setDrivingData([{
        "name": "driving",
        "color": "#ed1c24",
        "path": finalRoute.geometry.coordinates
      }])

      setDuzinaPuta(finalRoute.distance)
    })()
  }

  useEffect(() => {
    if (pronadeniBus && pocetnaLokacija && odredisnaLokacija) {
      let indexA; //Indeks stanice A u nizu stanica pripadajuceg autobusa ('pronadeniBus')
      let indexB; //Indeks stanice B u nizu stanica pripadajuceg autobusa ('pronadeniBus')
      if(pronadeniBus.stanice.indexOf(stanicaA.ime) < pronadeniBus.stanice.indexOf(stanicaB.ime)) {
        indexA = pronadeniBus.stanice.indexOf(stanicaA.ime);
        indexB = pronadeniBus.stanice.indexOf(stanicaB.ime);
      } else {
        indexA = pronadeniBus.stanice.indexOf(stanicaA.ime);
        indexB = pronadeniBus.stanice.indexOf(stanicaB.ime, 2);
      }

      //Varijabla u kojoj se nalazi niz potrebnih stanica za prikaz rute autobusa
      const potrebneStanice = pronadeniBus.stanice.slice(indexA, indexB + 1);

      if (potrebneStanice.includes('DomovinskogRata8B') && potrebneStanice.includes('Zagrebacka1B')) {
        handleCustom('DomovinskogRata8B', 'Zagrebacka1B', potrebneStanice)
      } else if (potrebneStanice.includes('Zagrebacka1A') && potrebneStanice.includes('Zagrebacka1B')) {
        handleCustom('Zagrebacka1A', 'Zagrebacka1B', potrebneStanice)
      } else if (potrebneStanice.includes('DomovinskogRata8B') && potrebneStanice.includes('StajalisteHNK')) {
        handleCustom('DomovinskogRata8B', 'StajalisteHNK', potrebneStanice)
      } else if (potrebneStanice.includes('StajalisteHNK') && potrebneStanice.includes('Vukovarska1A')) {
        handleCustom('StajalisteHNK', 'Vukovarska1A', potrebneStanice)
      } else if (potrebneStanice.includes('StajalisteHNK') && potrebneStanice.includes('Zagrebacka1B')) {
        handleCustom('StajalisteHNK', 'Zagrebacka1B', potrebneStanice)
      } else if (potrebneStanice.includes('Zagrebacka1A') && potrebneStanice.includes('StajalisteHNK')) {
        handleCustom('Zagrebacka1A', 'StajalisteHNK', potrebneStanice)
      } else if (potrebneStanice.includes('Zagrebacka1A') && potrebneStanice.includes('DomovinskogRata1A')) {
        handleCustom('Zagrebacka1A', 'DomovinskogRata1A', potrebneStanice)
      } else {
        handleNormal(potrebneStanice)
      }
    }
  }, [stanicaA, stanicaB, pronadeniBus]);

  //Generiranje rute setanja od pocetne lokacije do pocetne stanice i od odredisne stanice do lokacije odredista

  useEffect(() => {
    if (pronadeniBus && pocetnaLokacija && odredisnaLokacija) {
      const walkingCoords =
        pocetnaLokacija.longitude +
        "," +
        pocetnaLokacija.latitude +
        ";" +
        stanicaA.lokacija.longitude +
        "," +
        stanicaA.lokacija.latitude;

      const walkingCoords2 =
        stanicaB.lokacija.longitude +
        "," +
        stanicaB.lokacija.latitude +
        ";" +
        odredisnaLokacija.longitude +
        "," +
        odredisnaLokacija.latitude;
      (async () => {
        const finalRoute = await matchWalking(walkingCoords);
        const finalRoute2 = await matchWalking(walkingCoords2);
        setWalkingData([
          {
            "name": "walking_1",
            "color": "#FF5733",
            "path": finalRoute.geometry.coordinates
          },
          {
            "name": "walking_2",
            "color": "#FF5733",
            "path": finalRoute2.geometry.coordinates
          }
        ])
      })()
    }
  }, [stanicaA, stanicaB]);

  //API za generiranje rute autobusa
  const matchRoute = async (e) => {
    try {
      //Bus route
      const url =
        "https://api.mapbox.com/matching/v5/mapbox/driving/" +
        e +
        "?geometries=geojson&access_token=" +
        map_key;
      let coords;
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          //coords = json.matchings[0].geometry.coordinates;
          coords = json.matchings[0]
        });
      return coords
    }
    catch {
      console.log('error');
      matchRoute(e)
    }
  };

  //API za generiranje rute setnje korisnika
  const matchWalking = async (e) => {
    try {
      //User's walking path
      const url =
        "https://api.mapbox.com/directions/v5/mapbox/cycling/" +
        e +
        "?geometries=geojson&access_token=" +
        map_key;
      let coords;
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          coords = json.routes[0];
        });
      return coords;
    }
    catch {
      console.log('error');
      matchWalking(e)
    }
  };

  //Prikaz mape
  return (
    <div className="map_wrap">
      <DeckGL
      initialViewState={viewport}
      controller={true}
      layers={[drivingLayer, walkingLayer, markersLayer, locationsLayer]}
      >
        <StaticMap mapboxApiAccessToken={map_key} mapStyle={map_style} />
      </DeckGL>
    </div>
  );
};

export default Map;
