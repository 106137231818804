import React, { useState } from 'react'
import {useTranslation} from 'react-i18next'

const Navbar = ({ theme, logoColor, hamColor, backHam, displayLogo, rightAlign, hamStyle }) => {
    
    const {t} = useTranslation()
    const [isActive, setActive] = useState(false)

    const ToggleClass = () => {
        setActive(!isActive)
    };

    return (
        <nav className="navbar" style={rightAlign} data-theme={theme}>
            <a href="/" style={{textDecoration: "none"}}>
            <div style={displayLogo} className="logo-nav">
                <h1 style={logoColor}>CB</h1>
            </div>
            </a>
            <div className="hamburger " style={backHam}>
                <svg style={hamStyle} viewBox="0 0 100 100" width="80"
                    onClick={ToggleClass} className={isActive ? "active ham hamRotate" : "ham hamRotate"}>
                    <path style={hamColor}
                        className="line top"
                        d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                    <path style={hamColor}
                        className="line middle"
                        d="m 70,50 h -40" />
                    <path style={hamColor}
                        className="line bottom"
                        d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
                </svg>
            </div>
            <div className={"overlay"} style={{ width: isActive ? "100%" : "0%" }}>
                <ul>
                    <li><a href="/">{t("home")}</a></li>
                    <li><a href="/FindBus">{t("findbus")}</a></li>
                    <li><a href="/Help">{t("help")}</a></li>
                    <li><a href="/Settings">{t("settings")}</a></li>
                    <li><a href="/Contact">{t("contact")}</a></li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar
