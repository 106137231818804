import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Backend from "i18next-http-backend"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translationEN.json"
import translationHr from "./locales/hr/translationHr.json"
const resources = {
    en: {
        translation: translationEN
    },
    hr: {
        translation: translationHr
    }
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        resources,
        keySeparator: false,
        //fallbackLng: "en",
        supportedLngs: ['hr', 'en'],

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
export default i18n