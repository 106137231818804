import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";

import { motion } from "framer-motion";

import Button from "../components/Button";

import { useTranslation } from "react-i18next";

const Contact = ({ theme, textInput }) => {
  const { t } = useTranslation();

  const [pointEvents, setPointEvents] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [emails, setEmail] = useState("");
  const [messages, setMessage] = useState("");

  console.log(firstName, emails, messages, pointEvents);
  useEffect(() => {
    if (firstName && emails && messages) {
      setPointEvents(true);
    }
  }, [firstName, emails, messages]);

  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };

    let response = await fetch("http://localhost:3000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    console.log(result);
  };

  console.log(theme);
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
          transition: {
            delay: 0.5,
            duration: 0.5,
          },
        },
      }}
    >
      <div className={"contact-wrapper"} data-theme={theme}>
        <section className={"contact-us font-size-changer"}>
          <header className={"contact-header"}>
            <Navbar
              theme={theme}
              hamColor={{ stroke: "#fff" }}
              logoColor={{ color: "#fff" }}
            />
            <svg
              className={"phone-ilu"}
              width="126"
              height="215"
              viewBox="0 0 126 215"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M102.322 62.1958C102.375 61.9305 102.562 61.7122 102.817 61.6206C104.735 60.9299 106.697 60.3686 108.68 59.9432C108.774 59.9216 108.871 59.8986 108.998 59.8689C110.631 59.4828 111.195 59.3857 112.025 59.4373C113.197 59.5101 114.181 60.0121 115.011 61.0373C116.201 62.5111 116.99 64.4707 117.391 66.6643C117.622 67.9587 117.373 69.8058 116.718 70.8534C115.986 72.0415 114.795 73.0959 113.076 74.2455C112.768 74.4507 112.448 74.6571 112.078 74.8888C111.933 74.9801 111.785 75.0721 111.601 75.1856C111.536 75.2261 111.23 75.4147 111.148 75.4659C110.46 75.8906 109.881 76.2767 108.743 77.0559C108.619 77.1414 108.619 77.1414 108.494 77.2269C106.766 78.4095 105.855 78.9951 104.721 79.6045C102.912 80.5285 101.2 81.6315 99.6111 82.8973C99.0502 83.3443 98.2371 82.8492 98.3761 82.1458L102.322 62.1958Z"
                fill="#FBD2AE"
              />
              <path
                d="M107.282 107.793C112.344 106.914 121.779 104.009 123.593 102.129C125.367 100.315 126.025 97.8299 125.999 94.455C125.991 93.4891 125.94 92.6252 125.82 91.1087L125.782 90.6271C125.491 87.4367 124.887 84.2918 123.977 81.2292C122.923 77.5248 121.28 76.6798 116.745 76.9258C112.053 77.2349 107.398 77.8836 102.808 78.8665C102.41 78.952 102.147 79.3327 102.209 79.7354L106.39 107.15C106.455 107.577 106.857 107.867 107.282 107.793Z"
                fill="#FBD2AE"
              />
              <path
                d="M123.989 89.4857C122.823 89.64 121.473 89.8025 119.86 89.985C118.664 90.1205 117.448 90.2533 115.59 90.4531C110.403 91.0106 109.358 91.1257 107.423 91.3636C107.001 91.4156 106.7 91.7998 106.752 92.2218C106.804 92.6437 107.189 92.944 107.611 92.8921C109.535 92.6557 110.578 92.5409 115.755 91.9842C117.616 91.7841 118.833 91.6512 120.033 91.5153C121.655 91.3317 123.014 91.1681 124.191 91.0125C124.613 90.9567 124.909 90.5698 124.854 90.1482C124.798 89.7267 124.411 89.4302 123.989 89.4857Z"
                fill="#FCB69F"
              />
              <path
                d="M96.686 98.3746C93.8463 94.8358 91.0074 92.2437 88.0396 90.7088C83.8778 88.5513 79.0936 87.1615 74.6901 87.0013C69.4369 86.81 64.9495 88.3407 61.7464 91.7971C61.0461 92.5544 60.4875 93.5065 59.9605 94.7854L57.7306 87.5318C57.5689 87.006 56.9237 86.8146 56.501 87.1673C55.0265 88.3981 52.8944 91.3898 50.2457 95.7171C48.4636 98.6291 46.7654 101.612 45.0507 104.739C44.6114 105.542 44.1598 106.441 43.6947 107.432C42.5505 109.873 41.3486 112.823 40.0235 116.373C39.3948 118.059 38.7619 119.813 38.0146 121.929C37.7055 122.803 36.5478 126.105 36.2841 126.851C32.5821 137.313 30.3572 148.779 27.8887 166.989C27.4043 170.567 27.0109 175.879 26.6967 182.474C26.485 186.919 26.3218 201.305 26.2013 206.065C26.1589 207.731 26.125 209.278 26.0989 210.667C26.0896 211.153 26.0819 211.585 26.0758 211.956C26.0734 212.087 26.0723 212.163 26.0704 212.297C26.0688 212.394 26.0688 212.394 26.0684 212.414C26.0623 212.843 26.4084 213.195 26.8376 213.195L88.0238 213.26C88.4492 213.261 88.7945 212.916 88.7945 212.49C88.7945 196.106 89.6661 165.155 91.2107 157.917C92.45 152.125 94.7145 145.019 97.2704 138.652C98.47 135.656 99.8983 132.416 101.823 128.253C102.4 128.164 102.974 128.051 103.542 127.914C106.284 127.238 107.09 127.026 108.501 126.57C113.968 124.8 117.25 122.288 117.834 118.216C118.31 114.906 116.794 110.442 114.436 108.181C112.746 106.561 110.398 106.138 107.279 106.507C106.349 106.617 105.443 106.779 104.131 107.052C103.933 107.093 103.276 107.231 103.183 107.251C103.052 107.278 102.932 107.303 102.821 107.326C100.426 103.449 98.711 100.899 96.686 98.3746Z"
                fill="#FBD2AE"
              />
              <path
                d="M97.6267 114.026C92.9272 114.517 90.2153 118.359 91.582 122.722C91.5932 122.757 92.0848 124.456 92.2719 125.021C92.7809 126.559 93.3164 127.613 94.0598 128.242C96.1033 129.975 98.1018 130.308 100.901 129.619C101.315 129.517 101.567 129.1 101.465 128.687C101.363 128.274 100.946 128.022 100.533 128.123C98.1823 128.702 96.6901 128.454 95.0558 127.067C94.5938 126.676 94.1584 125.82 93.7337 124.537C93.5578 124.006 93.0719 122.326 93.0523 122.264C91.9836 118.852 94.0313 115.95 97.7869 115.558C98.2096 115.514 98.5168 115.135 98.4726 114.712C98.4283 114.289 98.0498 113.982 97.6267 114.026Z"
                fill="#FCB69F"
              />
              <path
                d="M93.831 116.274L93.8287 116.274C89.8537 116.971 78.2502 117.556 73.0859 117.441C72.5581 117.429 72.0253 117.423 71.2646 117.419C71.1348 117.418 71.0313 117.417 70.7972 117.416C65.7431 117.389 63.6002 117.209 61.246 116.329C58.0541 115.137 55.9971 112.761 55.1078 108.833C54.1557 104.624 53.4754 100.09 52.8618 94.3531C52.6327 92.2133 52.5137 90.9717 52.0987 86.5124C52.014 85.6004 51.954 84.9578 51.8931 84.3199C51.3518 78.6101 50.8633 72.306 50.163 62.3983C50.2292 63.3369 49.5024 52.9823 49.3079 50.287C49.0377 46.5337 48.8059 43.4654 48.5811 40.7012C48.0225 33.7379 47.9397 26.7738 48.8706 23.9819C50.0036 20.496 53.0504 17.98 56.6685 17.5295C65.8659 16.15 77.4244 15.1067 83.5557 15.2344C84.1043 15.2459 84.5894 15.2508 85.6423 15.2578C88.7473 15.2785 90.1936 15.3155 92.022 15.4653C97.8129 15.9398 101.857 17.4251 104.182 20.618C105.449 22.3619 106.199 24.8805 106.667 28.2753C106.785 29.1253 106.882 30.0135 106.978 31.0437C107.014 31.4387 107.048 31.836 107.091 32.3508C107.083 32.2561 107.17 33.3036 107.193 33.5704C107.221 33.9042 107.246 34.1821 107.271 34.4401C107.289 34.6453 107.289 34.6453 107.308 34.8501C108.082 43.3029 108.104 43.5466 108.424 46.7043C108.642 48.8552 108.857 50.8448 109.078 52.7455C111.091 69.7727 112.297 84.1355 112.748 98.0663C112.954 104.353 112.016 108.628 108.756 111.428C107.209 112.749 104.69 113.808 101.386 114.7C100.025 115.067 98.5887 115.39 97.0303 115.694C96.4439 115.808 95.8672 115.914 95.215 116.03C94.9475 116.078 94.02 116.24 93.831 116.274Z"
                className={"waveIluColor"}
              />
              <path
                d="M92.1207 113.06C87.5563 113.566 82.9649 113.79 78.3727 113.73C71.6701 113.583 66.03 114.476 64.2091 106.434C62.5883 99.2732 61.9915 89.9988 61.2947 82.661C60.1628 70.7264 59.2543 54.1719 58.2802 42.1756C58.0184 38.9418 57.26 27.8272 58.5112 24.9128C59.7624 21.9984 62.8308 21.0937 65.0368 20.6779C70.3381 19.6539 76.7982 18.8569 82.8617 18.984C88.3786 19.0995 97.4027 18.5258 101.083 23.6038C103.247 26.5836 103.428 33.8599 103.778 37.3826C104.302 42.8609 104.791 48.5087 105.449 54.0679C107.066 67.7504 108.336 83.327 108.787 97.1866C108.914 101.036 108.671 105.987 105.395 108.798C102.334 111.412 94.7655 112.736 92.1207 113.06Z"
                fill="white"
              />
              <path
                d="M55.5465 19.857C53.6608 21.118 53.0548 24.3315 53.1407 29.4045C53.1622 30.6919 53.2238 31.9639 53.3366 33.7317C53.3524 33.9824 53.4202 35.0238 53.4367 35.2828C53.4718 35.8357 53.4952 36.2457 53.5114 36.6053C53.6631 39.8946 53.8929 43.7102 54.2437 48.9738C54.2929 49.7149 54.3453 50.4903 54.405 51.3723C54.4442 51.9532 54.6787 55.4069 54.7441 56.3771C54.8704 58.2458 54.967 59.7111 55.0521 61.0505C55.8444 73.6519 57.0421 87.0949 58.6468 101.989C59.0868 106.104 60.0062 109.959 61.396 112.654C61.5911 113.032 62.0554 113.181 62.4335 112.985C62.8112 112.791 62.9598 112.326 62.7646 111.948C61.4791 109.455 60.5994 105.768 60.1782 101.825C58.5755 86.9509 57.3798 73.5302 56.589 60.9531C56.5035 59.611 56.4069 58.1442 56.2806 56.2735C56.2152 55.3026 55.9807 51.8489 55.9415 51.2683C55.8818 50.3867 55.8294 49.6117 55.7801 48.8714C55.4302 43.6174 55.2011 39.8107 55.0498 36.5348C55.0329 36.1649 55.009 35.7468 54.9736 35.1858C54.957 34.9248 54.8893 33.8826 54.8735 33.6339C54.7622 31.888 54.7014 30.6364 54.6802 29.3787C54.6044 24.8697 55.1499 21.9746 56.4027 21.1369C56.7561 20.9005 56.8508 20.4224 56.6144 20.0689C56.378 19.7155 55.8999 19.6205 55.5465 19.857Z"
                fill="white"
              />
              <path
                d="M45.708 129.202C45.1247 129.188 44.7682 128.556 45.0577 128.05C46.0568 126.303 47.1936 124.455 48.6023 122.263C49.1809 121.363 49.7811 120.442 50.5792 119.226C50.8341 118.838 51.7684 117.416 51.6941 117.529C52.1407 116.85 52.4733 116.342 52.7964 115.848C53.6206 114.585 54.294 113.538 54.9142 112.55C59.74 104.863 64.7641 98.7316 70.1805 94.5083C73.2827 92.0898 76.9617 92.7127 79.2901 96.0062C80.5101 97.7287 80.5667 99.6809 79.7563 102.005C79.4379 102.918 79.0464 103.767 78.3522 105.129C77.456 106.888 77.4587 106.883 77.203 107.464C76.2748 109.576 75.6785 113.214 75.5468 116.604C75.4918 118.052 75.6604 119.703 76.0281 121.582C76.3133 123.04 76.6983 124.555 77.2388 126.433C77.3516 126.826 77.4687 127.225 77.6165 127.725C77.6554 127.856 77.9087 128.709 77.9796 128.95C78.1274 129.45 77.7451 129.949 77.2231 129.937L45.708 129.202Z"
                fill="#FBD2AE"
              />
              <path
                d="M52.2418 46.4429C52.0947 44.287 51.9842 42.1483 51.9122 40.0471C51.8976 39.6221 51.5411 39.2894 51.1161 39.3041C50.6914 39.3187 50.3584 39.6752 50.373 40.1002C50.4458 42.2188 50.5574 44.3747 50.7057 46.5476C50.7345 46.9719 51.1018 47.2926 51.5261 47.2633C51.9503 47.2344 52.271 46.8671 52.2418 46.4429Z"
                className={"waveIluColor"}
              />
              <path
                d="M53.0221 57.0462C52.8311 54.9857 52.6829 52.7982 52.5701 50.4544C52.5497 50.0298 52.1886 49.7018 51.7639 49.7222C51.3393 49.7426 51.0113 50.1037 51.0317 50.5283C51.1456 52.8937 51.2958 55.1035 51.4886 57.1878C51.5275 57.6113 51.9025 57.9228 52.326 57.8835C52.7495 57.8446 53.0609 57.4697 53.0221 57.0462Z"
                className={"waveIluColor"}
              />
              <path
                d="M75.3771 118.199C75.6289 120.839 76.2518 122.91 77.4987 125.903C77.5676 126.068 77.6373 126.234 77.7317 126.458C77.7894 126.595 77.7894 126.595 77.8468 126.731C77.9037 126.867 77.9268 126.922 77.9603 127.001C78.1417 127.433 78.2733 127.751 78.3973 128.06C78.5551 128.455 79.0033 128.647 79.3983 128.489C79.7929 128.331 79.9846 127.882 79.8268 127.488C79.6989 127.169 79.5646 126.844 79.3802 126.405C79.3463 126.324 79.3232 126.269 79.2658 126.133C79.2085 125.996 79.2085 125.996 79.1507 125.86C79.0572 125.638 78.9883 125.473 78.9205 125.311C77.7274 122.448 77.1445 120.509 76.9101 118.053C76.8697 117.629 76.4939 117.319 76.0704 117.36C75.6473 117.4 75.3366 117.776 75.3771 118.199Z"
                fill="#FCB69F"
              />
              <path
                d="M45.7357 126.917C46.714 125.206 47.8308 123.39 49.2168 121.234C49.7939 120.337 50.3929 119.417 51.1914 118.202C51.4466 117.813 52.3783 116.396 52.3109 116.499C52.7594 115.816 53.0948 115.305 53.4197 114.807C54.2517 113.533 54.9323 112.475 55.5599 111.475C55.7858 111.115 55.6773 110.639 55.3169 110.413C54.957 110.187 54.4815 110.296 54.2555 110.656C53.6334 111.647 52.9577 112.698 52.1304 113.965C51.8062 114.461 51.4721 114.971 51.0239 115.653C51.0917 115.55 50.1596 116.967 49.904 117.357C49.1032 118.576 48.5015 119.499 47.9213 120.402C46.5223 122.578 45.3923 124.416 44.3991 126.152C44.1881 126.521 44.3159 126.992 44.6851 127.203C45.0543 127.414 45.5248 127.286 45.7357 126.917Z"
                fill="#FCB69F"
              />
              <path
                d="M77.7161 149.417C78.5049 149.598 79.2772 149.843 80.026 150.15C80.4195 150.311 80.8691 150.122 81.03 149.729C81.1914 149.335 81.0027 148.886 80.6093 148.725C79.7831 148.386 78.9307 148.116 78.0603 147.917C77.6456 147.822 77.2329 148.081 77.1378 148.495C77.0427 148.91 77.3018 149.323 77.7161 149.417Z"
                fill="#744B15"
              />
              <path
                d="M115.17 179.112C114.766 175.924 113.923 169.295 110.986 166.188C107.636 162.65 101.411 161.953 96.356 161.772C94.5746 161.703 92.7717 161.641 90.9469 161.587C90.6889 163.127 90.458 165.033 90.2501 167.177C91.0616 167.35 91.807 167.75 92.3983 168.332C93.6033 169.645 93.33 172.132 93.1683 173.776C93.0566 174.789 92.9103 175.813 92.7833 176.806C92.5523 178.442 92.3098 180.132 92.2174 181.842C92.0826 184.271 92.1057 186.723 92.125 189.095C92.125 190.25 92.1442 191.405 92.125 192.56C92.125 192.852 92.125 193.153 92.125 193.457C92.125 195.051 92.1558 196.698 91.482 198.003C91.4512 198.059 91.4293 198.119 91.4166 198.181C91.2872 198.737 91.1044 199.28 90.8699 199.801C89.9498 201.83 88.1095 202.396 85.4377 202.65C83.388 202.801 81.331 202.828 79.2779 202.731C77.8381 202.689 76.3443 202.646 74.8698 202.696C67.1931 202.943 59.3894 202.789 51.8475 202.643L48.194 202.573H47.1584C46.1035 202.573 45.0255 202.6 43.9745 202.623C40.0707 202.712 36.0361 202.8 32.4249 201.715C32.356 201.695 32.2847 201.684 32.2131 201.68C25.9147 201.572 20.5365 201.091 18.5153 196.29C17.6375 194.2 18.3074 188.09 20.267 180.344C22.2266 172.598 24.3209 167.578 25.0139 167.081C26.0279 166.568 27.1498 166.305 28.2863 166.311C28.4865 164.869 28.6955 163.407 28.9138 161.926H28.371L24.0167 161.965H22.1419C17.7799 161.965 11.7972 161.965 8.25919 163.628C1.97234 166.588 1.18697 174.023 0.613334 179.459C0.544036 180.113 0.478588 180.737 0.40544 181.322C-0.322187 187.147 0.0204545 197.649 0.717283 205.006C1.03682 208.344 3.50845 211.089 7.33137 212.352C11.5431 213.738 24.625 213.799 26.1034 213.799C32.0784 213.799 38.6925 213.876 45.6916 213.946C53.476 214.027 61.4761 214.104 68.7562 214.104C71.1431 214.104 73.453 214.104 75.6629 214.077L78.7736 214.054C87.0162 213.992 95.5398 213.926 103.594 213.053C113.303 211.998 113.988 205.576 114.566 199.909C114.635 199.243 114.705 198.577 114.789 197.915C115.251 194.231 115.763 183.751 115.224 179.663L115.17 179.112Z"
                className={"waveIluColor"}
              />
              <path
                d="M111.575 180.575C111.436 178.354 110.574 171.97 107.879 169.26C104.976 166.338 99.4091 165.795 95.0434 165.626C93.5265 165.568 91.9866 165.518 90.4235 165.472C89.6266 172.725 89.1685 183.62 88.949 192.779C82.4389 196.918 71.0971 199.566 58.2231 199.389C44.4637 199.197 32.5367 195.828 26.4308 191.02C26.7042 183.458 27.1585 174.623 27.8784 169.299C28.0455 168.082 28.2149 166.85 28.3866 165.603H27.016C23.0121 165.637 14.7695 165.279 11.2315 166.869C5.19104 169.564 5.0717 177.114 4.48267 181.691C3.82819 186.719 4.18623 192.148 4.89846 196.621C5.1949 198.488 5.95717 201.099 9.13333 202.342C12.3095 203.586 24.9563 203.751 28.633 203.751C42.2538 203.782 61.0374 204.182 74.6275 204.075C82.9817 204.009 93.4919 204.159 101.738 203.305C110.978 202.358 110.443 197.603 111.174 192.044C111.629 188.239 111.763 184.402 111.575 180.575Z"
                fill="white"
              />
              <path
                d="M26.4076 191.998C26.5962 186.504 26.885 180.209 27.3007 175.027C26.2755 175.682 25.3161 176.435 24.4364 177.276C22.3998 179.258 21.3565 181.407 21.3219 183.651C21.2834 186.7 23.1313 189.549 26.4076 191.998Z"
                className={"waveIluColor"}
              />
              <path
                d="M89.6223 175.751C89.2874 181.603 89.0641 188.028 88.937 193.703C90.194 192.957 91.3628 192.07 92.4212 191.062C94.4616 189.079 95.5011 186.931 95.5396 184.683C95.5819 181.387 93.4144 178.342 89.6223 175.751Z"
                className={"waveIluColor"}
              />
              <path
                d="M3.26339 204.734C4.51884 206.789 8.11617 207.676 13.9064 207.993C15.6211 208.088 16.87 208.115 19.8529 208.149C20.8735 208.16 21.3986 208.168 21.913 208.182C25.6246 208.299 29.9219 208.378 35.9373 208.453C36.8316 208.465 37.766 208.476 38.8778 208.489C39.3094 208.494 39.7475 208.499 40.3373 208.506C41.0634 208.514 41.4469 208.519 41.7845 208.522C45.4511 208.565 47.5497 208.591 49.641 208.625C63.939 208.85 79.23 208.727 96.1979 208.305C100.857 208.187 105.281 207.546 108.43 206.329C108.826 206.175 109.023 205.73 108.87 205.333C108.717 204.937 108.271 204.739 107.875 204.893C104.923 206.034 100.657 206.651 96.1594 206.766C79.2111 207.187 63.9402 207.31 49.6652 207.085C47.5716 207.052 45.4712 207.025 41.8022 206.982C41.4646 206.979 41.0811 206.974 40.355 206.966C39.7652 206.959 39.3271 206.954 38.8959 206.949C37.7845 206.936 36.8505 206.925 35.9566 206.914C29.95 206.838 25.6612 206.76 21.9584 206.643C21.4298 206.628 20.8989 206.62 19.8702 206.609C16.9104 206.575 15.6754 206.548 13.9911 206.456C8.76834 206.169 5.44435 205.35 4.57736 203.931C4.35599 203.568 3.88207 203.454 3.51903 203.675C3.15637 203.897 3.04164 204.371 3.26339 204.734Z"
                fill="white"
              />
              <path
                d="M23.5817 210.042C21.517 210.042 18.7586 209.986 17.7965 209.931C17.3718 209.908 17.0084 210.232 16.9841 210.657C16.9603 211.081 17.2852 211.445 17.7095 211.469C18.7054 211.525 21.4908 211.582 23.5817 211.582C24.0071 211.582 24.3517 211.237 24.3517 210.812C24.3517 210.387 24.0071 210.042 23.5817 210.042Z"
                fill="white"
              />
              <path
                d="M33.8527 209.961C31.9698 210.008 30.1915 210.036 27.7641 210.042C27.3387 210.043 26.9949 210.388 26.9961 210.814C26.9973 211.239 27.3426 211.583 27.768 211.582C30.208 211.576 31.9971 211.548 33.8908 211.501C34.3163 211.49 34.6523 211.137 34.6416 210.712C34.6312 210.287 34.2781 209.951 33.8527 209.961Z"
                fill="white"
              />
              <path
                d="M91.1602 178.97C93.0609 180.798 94.0241 182.728 94.0041 184.667C93.9001 192.462 77.9305 198.895 58.2491 198.619C38.5581 198.344 22.7655 191.474 22.8653 183.672C22.8926 181.682 23.9613 179.728 26.016 177.914C26.3351 177.633 26.3656 177.147 26.0841 176.828C25.8027 176.509 25.3161 176.478 24.9973 176.76C22.6404 178.839 21.3592 181.182 21.3253 183.652C21.2102 192.67 37.7685 199.873 58.2276 200.159C78.6777 200.445 95.4239 193.7 95.544 184.685C95.5687 182.279 94.4126 179.961 92.2274 177.86C91.9209 177.565 91.4335 177.575 91.139 177.881C90.8441 178.188 90.8537 178.675 91.1602 178.97Z"
                className={"waveIluColor"}
              />
              <path
                d="M65.5084 40.4922C63.453 43.1136 62.184 48.038 62.4909 51.6765C62.7908 55.1433 63.7067 58.527 65.1958 61.67C67.0869 65.5811 70.5672 68.522 74.7678 70.1035C78.9953 71.6951 84.3921 72.0138 88.6858 70.6679C93.1275 69.2758 96.841 66.5266 98.6424 63.4248C100.671 59.8898 101.594 56.7002 101.518 53.0128C101.4 47.3053 99.8724 42.1038 96.8661 38.6624C92.7282 33.912 86.0082 32.0009 79.4465 32.9326C73.704 33.3934 69.1581 35.8304 65.5084 40.4922Z"
                className={"contactIlu"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80.3077 41.4916C76.5379 42.2963 74.3361 46.3309 75.5223 50.2817C76.1633 52.4134 77.6074 54.0473 79.3325 54.8038C79.14 54.9273 78.9525 55.0636 78.7708 55.2122C75.6913 57.7531 72.8312 62.1324 70.79 67.2335L70.7727 67.2724C70.6218 67.6173 70.7423 68.0208 71.058 68.2264C72.2202 68.9832 73.4614 69.6127 74.7592 70.1032C78.9945 71.6928 84.3882 72.0127 88.6785 70.668C92.2169 69.5615 95.2964 67.5965 97.3322 65.2304C97.5659 64.959 97.5813 64.562 97.3696 64.2729L97.3388 64.231C96.2535 62.5844 95.0292 61.0344 93.6795 59.5969C91.2417 57.0336 88.3986 55.1229 85.6894 54.2894C87.8207 53.1287 89.0561 50.9489 89.018 48.1327C88.9564 43.6022 85.2444 40.4441 80.3077 41.4916Z"
                fill="#EAF3F3"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M83.6219 76.5298L83.6184 76.5301C79.6122 76.8039 70.89 77.647 68.9204 78.0324C68.5327 78.1082 68.2651 78.4655 68.3017 78.8593C68.3537 79.4187 68.4472 80.303 68.5716 81.4253L68.5966 81.652C68.6705 82.3165 68.7498 83.0156 68.8291 83.7059C68.8568 83.9477 68.8826 84.171 68.9057 84.3708L68.935 84.6214C68.9835 85.0388 69.3585 85.3402 69.7766 85.2982L69.9271 85.2832C70.0503 85.2709 70.1943 85.2567 70.3579 85.2405C70.8253 85.1947 71.3535 85.1439 71.9325 85.0888C73.5868 84.9317 75.3135 84.7743 77.0267 84.6276C77.8975 84.5533 78.7437 84.4832 79.5591 84.4185C80.927 84.31 82.1882 84.218 83.3197 84.1456C84.9563 84.0405 87.0425 83.8607 89.4752 83.6209C90.2098 83.5485 90.969 83.4711 91.7486 83.3899C93.5446 83.202 95.3663 83.0014 97.1196 82.8004C97.7332 82.73 98.2942 82.6645 98.7916 82.606C98.9656 82.5856 99.1192 82.5675 99.2512 82.5517L99.4125 82.5325C99.7848 82.4874 100.07 82.181 100.09 81.8068C100.126 81.0842 100.093 80.5933 99.8534 77.6963C99.8079 77.1438 99.7748 76.7257 99.7448 76.3172C99.7298 76.1124 99.7163 75.9169 99.704 75.7274C99.6751 75.2828 99.2755 74.9552 98.8339 75.0141C92.5694 75.8499 88.5871 76.2125 83.6219 76.5298ZM70.1019 81.2559C70.023 80.5417 69.9564 79.925 69.9055 79.4299C72.6674 79.0245 80.1474 78.3107 83.7216 78.0666C88.5004 77.7609 92.3819 77.4133 98.2252 76.6479C98.2514 76.9956 98.2807 77.3636 98.3188 77.8233C98.4739 79.6974 98.5402 80.5417 98.5567 81.083C98.0747 81.14 97.5338 81.2027 96.944 81.2705C95.1958 81.4707 93.379 81.6713 91.5888 81.858C90.8119 81.9392 90.0558 82.0162 89.3243 82.0882C86.9074 82.3265 84.8369 82.5052 83.2211 82.6087C82.0812 82.6819 80.8126 82.7743 79.4374 82.8832C78.6186 82.9483 77.7689 83.0187 76.8954 83.0934C75.1768 83.2405 73.4455 83.3979 71.787 83.5558C71.2753 83.6043 70.8033 83.6497 70.3775 83.6913L70.3591 83.5304C70.2801 82.8416 70.2008 82.144 70.1273 81.4815L70.1019 81.2559Z"
                fill="#EAF3F3"
              />
              <path
                d="M76.1235 89.858L78.8145 89.6463C79.2384 89.6128 79.5552 89.242 79.5217 88.8182C79.4882 88.3943 79.1175 88.0775 78.6936 88.1109L76.0026 88.3227C75.5787 88.3562 75.2622 88.7269 75.2953 89.1508C75.3288 89.5747 75.6996 89.8911 76.1235 89.858Z"
                fill="#EAF3F3"
              />
              <path
                d="M83.5282 89.2729L86.2192 89.0573C86.6431 89.023 86.9592 88.6519 86.9253 88.228C86.8914 87.8041 86.5203 87.4881 86.0964 87.522L83.4053 87.7376C82.9815 87.7718 82.6654 88.1429 82.6993 88.5668C82.7331 88.9907 83.1043 89.3068 83.5282 89.2729Z"
                fill="#EAF3F3"
              />
              <path
                d="M91.0727 88.6723L93.7637 88.4606C94.1876 88.4271 94.5045 88.0563 94.471 87.6324C94.4375 87.2086 94.0671 86.8917 93.6429 86.9252L90.9518 87.137C90.5279 87.1705 90.2115 87.5412 90.2446 87.9651C90.2781 88.389 90.6488 88.7054 91.0727 88.6723Z"
                fill="#EAF3F3"
              />
              <path
                d="M105.458 9.83714L109.427 1.37126C109.653 0.889982 109.446 0.31689 108.965 0.0912482C108.483 -0.134393 107.91 0.0728462 107.684 0.55412L103.715 9.02C103.49 9.50128 103.697 10.0744 104.178 10.3C104.659 10.5257 105.233 10.3185 105.458 9.83714Z"
                fill="#CCC9C6"
              />
              <path
                d="M121.096 5.78158L112.623 11.8451C112.191 12.1545 112.091 12.7557 112.4 13.1879C112.71 13.6202 113.311 13.7199 113.743 13.4105L122.217 7.34698C122.649 7.03768 122.749 6.43648 122.439 6.00418C122.13 5.57192 121.529 5.47224 121.096 5.78158Z"
                fill="#CCC9C6"
              />
              <path
                d="M116.328 23.446L124.047 23.2959C124.579 23.2855 125.001 22.8462 124.991 22.3146C124.981 21.7833 124.541 21.3609 124.01 21.3713L116.291 21.5215C115.759 21.5315 115.337 21.9708 115.347 22.5024C115.357 23.0337 115.797 23.4561 116.328 23.446Z"
                fill="#CCC9C6"
              />
            </svg>
            <svg
              className={"wave"}
              width="568"
              height="54"
              viewBox="0 0 568 54"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
            >
              <path
                d="M152.125 27.6647C179.578 22.3759 206.16 12.8962 233.538 7.14656C272.536 -1.04895 313.148 -1.54401 352.084 6.9554C389.923 15.195 429.157 35.2916 466.546 45.5017C499.703 54.5599 535.903 58.29 568 46.9722V0H0V13.4059C48.5004 32.4508 101.11 37.3819 152.125 27.6647Z"
                className={"waveIluCont"}
              />
            </svg>
          </header>
          <div className="contact-info-wrapper">
            <h1 className={"main-title"}>{t("contact")}</h1>
            <div className="contact-form">
              <form onSubmit={handleSubmit}>
                <input
                  required={true}
                  className={"text-input"}
                  type="text"
                  name="name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={t("contactName")}
                />
                <input
                  className={"text-input email-input"}
                  name="email"
                  type="text"
                  placeholder={t("contactEmail")}
                  value={emails}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <textarea
                  required={true}
                  name="message"
                  type={"text"}
                  placeholder={t("contactMessage")}
                  id={"textarea"}
                  value={messages}
                  onChange={(e) => setMessage(e.target.value)}
                  cols={20}
                  rows={10}
                />
                <span
                  style={pointEvents === false ? { pointerEvents: "none" } : {}}
                >
                  <Button
                    textInput={
                      pointEvents === false ? t("fillAll") : t("contactSend")
                    }
                    type={"submit"}
                  />
                </span>
              </form>
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default Contact;
