import React, { useState, useEffect } from "react";
import arrowBack from "../assets/img/arrowBack.svg";
import locationDash from "../assets/img/location-dash.svg";
import x from "../assets/img/x.svg";
import "../fix.css";
import { useTranslation } from "react-i18next";
import Sheet from "react-modal-sheet";
import toast, { Toaster } from "react-hot-toast";

const Dashboard = ({
  theme,
  isFirstInputActive,
  setIsFirstInputActive,
  setDrivingLayer,
  setWalkingLayer,
  setLocationsLayer,
  inputPocetnaAdresa,
  setInputPocetnaAdresa,
  inputOdredisnaAdresa,
  setInputOdredisnaAdresa,
  setIsExpandOpen,
  isDashboardOpen,
  setIsDashboardOpen,
  setIsUpLocateOpen,
  setPocetnaLokacija,
  setOdredisnaLokacija,
  lokacijaKorisnika,
}) => {
  const { t } = useTranslation();

  const zatvoriDashboard = () => {
    setIsDashboardOpen(false);
    setIsExpandOpen(true);
    setIsUpLocateOpen(false);
    setIsSecondInputActive(false);
    setPocetnaLokacija(null);
    setOdredisnaLokacija(null);
    setInputOdredisnaAdresa("");
    setInputPocetnaAdresa("");
    setWalkingLayer(null);
    setDrivingLayer(null);
    setLocationsLayer(null);
  };

  const apiKey =
    "pk.eyJ1IjoidGFyaWtiZXNpYyIsImEiOiJja3Znd2JzYjkxZ3E3MndxNWxpNHhncjFpIn0.doc_BQK1Yz9aVzRKbVNxEw";

  const croatia = {
    location: "16.440193,43.508133",
    domain: "hr",
  };

  const predlozenaMjesta = [
    {
      ime: "Mall of Split",
      adresa:
        "Mall of Split, Josipa Jovića 93, Split, Split-Dalmatia 21000, Croatia",
      lokacija: {
        latitude: 43.519801,
        longitude: 16.483292,
      },
    },
    {
      ime: "City Center One",
      adresa:
        "City Center One, Vukovarska 207, Split, Split-Dalmatia 21000, Croatia",
      lokacija: {
        latitude: 43.51367,
        longitude: 16.502483,
      },
    },
    {
      ime: "Stari pazar",
      adresa: "Stari Pazar, 21000 Split, Croatia",
      lokacija: {
        latitude: 43.508276,
        longitude: 16.4423654,
      },
    },
    {
      ime: "KBC Firule",
      adresa: "KBC Firule, Spinčićeva 1, Split, Split-Dalmatia 21000, Croatia",
      lokacija: {
        latitude: 43.504488,
        longitude: 16.457605,
      },
    },
    {
      ime: "Joker",
      adresa: "Joker, Put Brodarice 6, Split, Split-Dalmatia 21000, Croatia",
      lokacija: {
        latitude: 43.520243,
        longitude: 16.446645,
      },
    },
  ];

  const [inputPocetnaLokacija, setInputPocetnaLokacija] = useState();
  const [inputOdredisnaLokacija, setInputOdredisnaLokacija] = useState();

  const [locationPlaces, setLocationPlaces] = useState([]);
  const [destinationPlaces, setDestinationPlaces] = useState([]);

  const [isSecondInputActive, setIsSecondInputActive] = useState(false);

  useEffect(() => {
    const adresa = encodeURIComponent(inputPocetnaLokacija);
    const proximity = encodeURIComponent(croatia.location);
    const url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      adresa +
      ".json?country=" +
      croatia.domain +
      "&proximity=" +
      proximity +
      "&language=en&autocomplete=true&access_token=" +
      apiKey;

    if (adresa === "") {
      setLocationPlaces([]);
      setPocetnaLokacija(null);
      return;
    }

    setDestinationPlaces([]);

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.features) {
          const results = json.features;
          const show = results.map((result) => {
            return {
              ime: result.text,
              adresa: result.place_name,
              lokacija: {
                longitude: result.center[0],
                latitude: result.center[1],
              },
              id: result.id,
            };
          });
          setLocationPlaces(show);
        }
      });
  }, [inputPocetnaLokacija]);

  useEffect(() => {
    const adresa = encodeURIComponent(inputOdredisnaLokacija);
    const proximity = encodeURIComponent(croatia.location);
    const url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      adresa +
      ".json?country=" +
      croatia.domain +
      "&proximity=" +
      proximity +
      "&language=en&autocomplete=true&access_token=" +
      apiKey;

    if (adresa === "") {
      setDestinationPlaces([]);
      setOdredisnaLokacija(null);
      return;
    }

    setLocationPlaces([]);

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.features) {
          const results = json.features;
          const show = results.map((result) => {
            return {
              ime: result.text,
              adresa: result.place_name,
              lokacija: {
                longitude: result.center[0],
                latitude: result.center[1],
              },
              id: result.id,
            };
          });
          setDestinationPlaces(show);
        }
      });
  }, [inputOdredisnaLokacija]);

  const postaviPocetnuLokaciju = (place) => {
    setPocetnaLokacija({
      longitude: place.lokacija.longitude,
      latitude: place.lokacija.latitude,
    });
    setLocationPlaces([]);
    setIsFirstInputActive(false);
    setInputPocetnaAdresa(place.adresa);
    setIsUpLocateOpen(true);
  };

  const postaviOdredisnuLokaciju = (place) => {
    setOdredisnaLokacija({
      longitude: place.lokacija.longitude,
      latitude: place.lokacija.latitude,
    });
    setDestinationPlaces([]);
    setIsSecondInputActive(false);
    setInputOdredisnaAdresa(place.adresa);
    setIsUpLocateOpen(true);
  };

  const koristiLokacijuKorisnika = () => {
    try {
      const url =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        lokacijaKorisnika.longitude +
        "," +
        lokacijaKorisnika.latitude +
        ".json?limit=1&access_token=" +
        apiKey;

      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          const adresaKorisnika = json.features[0].place_name;
          setInputPocetnaAdresa(adresaKorisnika);
        });
      setIsFirstInputActive(false);
      setIsUpLocateOpen(true);
      setPocetnaLokacija(lokacijaKorisnika);
    } catch {
      toast.error(t("usernotlocatederror"));
      console.log("User location access denied");
    }
  };

  const izbrisiUnos = (type) => {
    if (type == "first") {
      setPocetnaLokacija(null);
      setInputPocetnaLokacija("");
      setInputPocetnaAdresa("");
    } else {
      setOdredisnaLokacija(null);
      setInputOdredisnaLokacija("");
      setInputOdredisnaAdresa("");
    }
  };

  return (
    <>
      <section className="dashboard">
        <Sheet
          isOpen={isDashboardOpen}
          className="sheet-container-fix"
          disableDrag={true}
          snapPoints={[1]}
          data-theme={theme}
        >
          <Sheet.Container>
            <Sheet.Header className="react-header-fix" />
            <Sheet.Content>
              <header className="header-dashboard">
                <div
                  className="arrowBack"
                  style={{ paddingLeft: "20px", paddingTop: "20px" }}
                  onClick={zatvoriDashboard}
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.77396 16L9.79058 13.9433L5.41592 9.45164L17 9.45164V6.54836L5.41592 6.54836L9.79058 2.05673L7.77396 0L0 8L7.77396 16Z"
                      fill="#626262"
                    />
                  </svg>
                </div>
                <div className="dash-container">
                  <div className="circle-square">
                    <div className="square"></div>
                    <div className="line"></div>
                    <div className="box"></div>
                  </div>
                  <div className="dashInputs">
                    <div className="clearInput">
                      <input
                        autoFocus
                        onClick={() => {
                          setIsFirstInputActive(true);
                          setIsSecondInputActive(false);
                        }}
                        value={inputPocetnaAdresa}
                        onChange={(event) => {
                          setInputPocetnaLokacija(event.target.value);
                          setInputPocetnaAdresa(event.target.value);
                        }}
                        type="text"
                        placeholder={t("firstinput")}
                      />
                      <div className="x-icons">
                        <svg
                          style={{
                            position: "absolute",
                            right: "45px",
                            top: "20px",
                          }}
                          onClick={() => {
                            izbrisiUnos("first");
                          }}
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <rect
                            y="7.57275"
                            width="10.7094"
                            height="0.577029"
                            rx="0.288514"
                            transform="rotate(-45 0 7.57275)"
                            fill="#C4C4C4"
                          />
                          <rect
                            x="0.40332"
                            y="0.137695"
                            width="10.8031"
                            height="0.570773"
                            rx="0.285387"
                            transform="rotate(45 0.40332 0.137695)"
                            fill="#C4C4C4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="clearInput">
                      <input
                        onClick={() => {
                          setIsSecondInputActive(true);
                          setIsFirstInputActive(false);
                        }}
                        value={inputOdredisnaAdresa}
                        onChange={(event) => {
                          setInputOdredisnaLokacija(event.target.value);
                          setInputOdredisnaAdresa(event.target.value);
                        }}
                        type="text"
                        placeholder={t("secondinput")}
                      />
                      <div className="x-icons">
                        <svg
                          style={{
                            position: "absolute",
                            right: "45px",
                            top: "20px",
                          }}
                          onClick={() => {
                            izbrisiUnos("second");
                          }}
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <rect
                            y="7.57275"
                            width="10.7094"
                            height="0.577029"
                            rx="0.288514"
                            transform="rotate(-45 0 7.57275)"
                            fill="#C4C4C4"
                          />
                          <rect
                            x="0.40332"
                            y="0.137695"
                            width="10.8031"
                            height="0.570773"
                            rx="0.285387"
                            transform="rotate(45 0.40332 0.137695)"
                            fill="#C4C4C4"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <Toaster position="bottom-center" reverseOrder={false} />
              <section className="auto-recommended">
                {locationPlaces.map((place) => (
                  <div
                    className="recommended"
                    key={place.id}
                    onClick={() => postaviPocetnuLokaciju(place)}
                  >
                    <svg
                      width="19"
                      height="29"
                      viewBox="0 0 19 29"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C4.51488 0 0 4.11196 0 9.18575C0 14.2583 4.11944 20.3145 9.5 29C14.8806 20.3145 19 14.2583 19 9.18575C19 4.11196 14.4863 0 9.5 0ZM9.5 13.2917C7.53231 13.2917 5.9375 11.6689 5.9375 9.66667C5.9375 7.66446 7.53231 6.04167 9.5 6.04167C11.4677 6.04167 13.0625 7.66446 13.0625 9.66667C13.0625 11.6689 11.4677 13.2917 9.5 13.2917Z"
                        fill="#626262"
                      />
                    </svg>
                    <div className="locateWrapper">
                      <div className="locationTitle">
                        <h1>{place.ime}</h1>
                        <p>{place.adresa}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {destinationPlaces.map((place) => (
                  <div
                    className="recommended"
                    key={place.id}
                    onClick={() => postaviOdredisnuLokaciju(place)}
                  >
                    <svg
                      width="19"
                      height="29"
                      viewBox="0 0 19 29"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C4.51488 0 0 4.11196 0 9.18575C0 14.2583 4.11944 20.3145 9.5 29C14.8806 20.3145 19 14.2583 19 9.18575C19 4.11196 14.4863 0 9.5 0ZM9.5 13.2917C7.53231 13.2917 5.9375 11.6689 5.9375 9.66667C5.9375 7.66446 7.53231 6.04167 9.5 6.04167C11.4677 6.04167 13.0625 7.66446 13.0625 9.66667C13.0625 11.6689 11.4677 13.2917 9.5 13.2917Z"
                        fill="#626262"
                      />
                    </svg>

                    <div className="locateWrapper">
                      <div className="locationTitle">
                        <h1>{place.ime}</h1>
                        <p>{place.adresa}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {locationPlaces.length < 1 && isFirstInputActive ? (
                  <>
                    <div
                      className="recommended"
                      onClick={() => koristiLokacijuKorisnika()}
                    >
                      <svg
                        width="19"
                        height="29"
                        viewBox="0 0 19 29"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.5 0C4.51488 0 0 4.11196 0 9.18575C0 14.2583 4.11944 20.3145 9.5 29C14.8806 20.3145 19 14.2583 19 9.18575C19 4.11196 14.4863 0 9.5 0ZM9.5 13.2917C7.53231 13.2917 5.9375 11.6689 5.9375 9.66667C5.9375 7.66446 7.53231 6.04167 9.5 6.04167C11.4677 6.04167 13.0625 7.66446 13.0625 9.66667C13.0625 11.6689 11.4677 13.2917 9.5 13.2917Z"
                          fill="#626262"
                        />
                      </svg>
                      <div className="locateWrapper">
                        <div className="locationTitle">
                          <h1>{t("yourlocation")}</h1>
                        </div>
                      </div>
                    </div>

                    {predlozenaMjesta.map((place) => (
                      <div
                        className="recommended"
                        key={place.ime}
                        onClick={() => postaviPocetnuLokaciju(place)}
                      >
                        <svg
                          width="19"
                          height="29"
                          viewBox="0 0 19 29"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C4.51488 0 0 4.11196 0 9.18575C0 14.2583 4.11944 20.3145 9.5 29C14.8806 20.3145 19 14.2583 19 9.18575C19 4.11196 14.4863 0 9.5 0ZM9.5 13.2917C7.53231 13.2917 5.9375 11.6689 5.9375 9.66667C5.9375 7.66446 7.53231 6.04167 9.5 6.04167C11.4677 6.04167 13.0625 7.66446 13.0625 9.66667C13.0625 11.6689 11.4677 13.2917 9.5 13.2917Z"
                            fill="#626262"
                          />
                        </svg>
                        <div className="locateWrapper">
                          <div className="locationTitle">
                            <h1>{place.ime}</h1>
                            <p>{place.adresa}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
                {destinationPlaces < 1 && isSecondInputActive ? (
                  <>
                    {predlozenaMjesta.map((place) => (
                      <div
                        className="recommended"
                        key={place.ime}
                        onClick={() => postaviOdredisnuLokaciju(place)}
                      >
                        <svg
                          width="19"
                          height="29"
                          viewBox="0 0 19 29"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C4.51488 0 0 4.11196 0 9.18575C0 14.2583 4.11944 20.3145 9.5 29C14.8806 20.3145 19 14.2583 19 9.18575C19 4.11196 14.4863 0 9.5 0ZM9.5 13.2917C7.53231 13.2917 5.9375 11.6689 5.9375 9.66667C5.9375 7.66446 7.53231 6.04167 9.5 6.04167C11.4677 6.04167 13.0625 7.66446 13.0625 9.66667C13.0625 11.6689 11.4677 13.2917 9.5 13.2917Z"
                            fill="#626262"
                          />
                        </svg>
                        <div className="locateWrapper">
                          <div className="locationTitle">
                            <h1>{place.ime}</h1>
                            <p>{place.adresa}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}

                {/*<div className="recommended">
                                <img src={locationDash} alt="Location Dash Icon" />
                                <div className="locateWrapper">
                                    <div className="locationTitle">
                                        <h1>Trgovački centar Joker</h1>
                                        <p>Put Brodarice 6, Split</p>
                                    </div>
                                </div>
                            </div>*/}
              </section>
            </Sheet.Content>
          </Sheet.Container>
        </Sheet>
      </section>
    </>
  );
};

export default Dashboard;
