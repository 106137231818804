import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Accordion from "../components/Accordion";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";


const Help = ({ theme }) => {
    //Help sekcija u kojoj korisnik moze pronaci odgovor na najpostavljenija pitanja

    const { t } = useTranslation();
    const [faqs, setFaqs] = useState([
        {
            question: t("helpquestion1"),
            answer: t("helpanswer1"),
            open: false,
        },
        {
            question: t("helpquestion2"),
            answer: t("helpanswer2"),
            open: false,
        },
        {
            question: t("helpquestion3"),
            answer: t("helpanswer3"),
            open: false,
        },
    ]);

    const toggleFaq = (index) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
                return faq;
            })
        );
    };

    return (
        <motion.div
            initial='hidden'
            animate='visible'
            variants={{
                hidden: {
                    opacity: 0,
                },
                visible: {
                    opacity: 1,
                    transition: {
                        delay: 0.5,
                        duration: 0.5,
                    },
                },
            }}>
            <div className={"help-wrapper"} data-theme={theme}>
                <section className='help font-size-changer'>
                    <div className='background-color'>
                        <Navbar theme={theme} />
                        <header>
                            <div className='text text-help'>
                                <h1>{t("helpText")}</h1>
                                <p>
                                    {t("helpTextPara")}
                                    <span className={"span"}> CatchBus</span>
                                </p>
                            </div>
                            <svg
                                className={"dark-eclipse"}
                                width='567'
                                height='419'
                                viewBox='0 0 567 419'
                                fill='none'
                                xmlns='https://www.w3.org/2000/svg'>
                                <ellipse
                                    cx='283.5'
                                    cy='209.5'
                                    rx='283.5'
                                    ry='209.5'
                                    className={"helpBackground"}
                                />
                            </svg>
                            <svg
                                className={"dark-girl"}
                                width='400'
                                height='369'
                                viewBox='0 0 439 369'
                                fill='none'
                                xmlns='https://www.w3.org/2000/svg'>
                                <path
                                    d='M49.0323 203.818C52.5178 203.818 55.3433 200.988 55.3433 197.497C55.3433 194.006 52.5178 191.176 49.0323 191.176C45.5468 191.176 42.7212 194.006 42.7212 197.497C42.7212 200.988 45.5468 203.818 49.0323 203.818Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M76.7036 255.842C80.1892 255.842 83.0147 253.012 83.0147 249.522C83.0147 246.031 80.1892 243.201 76.7036 243.201C73.2181 243.201 70.3926 246.031 70.3926 249.522C70.3926 253.012 73.2181 255.842 76.7036 255.842Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M55.3433 242.715C58.8288 242.715 61.6544 239.885 61.6544 236.394C61.6544 232.903 58.8288 230.073 55.3433 230.073C51.8578 230.073 49.0322 232.903 49.0322 236.394C49.0322 239.885 51.8578 242.715 55.3433 242.715Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M307.927 355.03H200.506C200.506 355.03 198.329 323.045 211.212 322.681C224.095 322.318 222.644 336.857 238.793 316.866C254.943 296.875 274.54 297.966 277.08 309.778C279.62 321.591 272.181 331.041 285.79 328.133C299.399 325.226 318.996 332.858 307.927 355.03Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M252.404 355.037L252.041 355.023C252.887 333.964 257.29 320.433 260.835 312.791C264.685 304.493 268.394 301.134 268.431 301.101L268.672 301.373C268.636 301.405 264.97 304.731 261.151 312.973C257.626 320.583 253.246 334.058 252.404 355.037Z'
                                    fill='#F2F2F2'
                                />
                                <path
                                    d='M291.282 355.081L290.934 354.979C295.129 340.558 305.008 331.453 305.107 331.363L305.351 331.632C298.798 338.163 293.964 346.22 291.282 355.081Z'
                                    fill='#F2F2F2'
                                />
                                <path
                                    d='M217.425 355.055L217.066 355.005C218.237 347.11 216.998 339.044 213.511 331.867C212.08 328.856 210.187 326.09 207.9 323.667L208.151 323.405C210.468 325.857 212.386 328.658 213.836 331.706C217.355 338.949 218.607 347.088 217.425 355.055Z'
                                    fill='#F2F2F2'
                                />
                                <path
                                    d='M322.506 355.516H188.92L188.889 355.063C188.812 353.935 187.108 327.341 195.712 317.852C196.566 316.85 197.619 316.037 198.805 315.467C199.991 314.898 201.283 314.583 202.598 314.543C208.206 314.383 211.731 316.487 214.573 318.176C219.931 321.359 223.471 323.462 236.34 307.532C250.282 290.274 265.324 286.561 273.971 288.625C279.559 289.96 283.409 293.73 284.536 298.971C285.604 303.933 285.264 308.596 284.991 312.343C284.698 316.351 284.467 319.518 286.111 320.952C287.484 322.15 290.223 322.259 294.731 321.295C303.9 319.336 316.695 321.012 323.117 329.323C326.572 333.793 329.294 341.92 322.641 355.247L322.506 355.516ZM189.832 354.544H321.905C326.863 344.47 327.018 335.959 322.349 329.918C316.458 322.294 304.098 320.288 294.933 322.246C290.035 323.293 287.117 323.12 285.473 321.686C283.464 319.932 283.71 316.551 284.022 312.272C284.291 308.587 284.625 304.002 283.587 299.176C282.542 294.316 278.955 290.815 273.746 289.572C265.375 287.571 250.753 291.237 237.095 308.143C223.698 324.727 219.662 322.33 214.078 319.012C211.2 317.303 207.933 315.364 202.626 315.515C201.442 315.553 200.279 315.839 199.212 316.354C198.145 316.869 197.198 317.602 196.431 318.506C188.698 327.034 189.642 350.948 189.832 354.544Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M348.758 354.907H157.125V355.879H348.758V354.907Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M144.92 251.651C145.09 252.833 145.097 254.033 144.94 255.217C144.907 255.486 144.866 255.75 144.819 256.01L144.819 256.012C144.256 258.814 143.131 261.471 141.512 263.825C141.406 263.977 141.346 264.057 141.346 264.057L141.119 263.884C139.322 262.446 137.763 260.732 136.501 258.806C136.39 258.631 136.279 258.455 136.174 258.273C135.236 256.74 134.604 255.039 134.312 253.264L134.308 253.238C134.28 253.049 134.256 252.86 134.238 252.671C134.09 251.013 134.232 249.342 134.658 247.733C134.709 247.533 134.762 247.335 134.819 247.14C135.465 244.977 136.442 242.927 137.716 241.064C137.822 240.912 137.882 240.832 137.882 240.832C137.882 240.832 137.963 240.891 138.109 241.006C139.856 242.402 141.378 244.06 142.621 245.92C142.73 246.087 142.838 246.256 142.944 246.429C143.672 247.602 144.233 248.871 144.611 250.198C144.676 250.43 144.733 250.667 144.783 250.905C144.837 251.15 144.882 251.399 144.92 251.651Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M203.79 276.253C204.554 277.17 205.189 278.188 205.675 279.279C205.789 279.524 205.892 279.77 205.988 280.017L205.989 280.018C206.977 282.699 207.412 285.553 207.267 288.407C207.257 288.593 207.248 288.692 207.248 288.692L206.964 288.664C204.68 288.384 202.455 287.745 200.372 286.768C200.186 286.678 199.999 286.586 199.815 286.487C198.213 285.674 196.783 284.558 195.605 283.201L195.588 283.181C195.465 283.035 195.345 282.887 195.231 282.736C194.237 281.402 193.482 279.905 193.002 278.311C192.94 278.114 192.882 277.918 192.828 277.721C192.245 275.541 192.003 273.282 192.111 271.027C192.121 270.842 192.131 270.742 192.131 270.742C192.131 270.742 192.23 270.749 192.415 270.771C194.634 271.042 196.797 271.653 198.831 272.583C199.011 272.668 199.191 272.755 199.372 272.846C200.606 273.462 201.748 274.247 202.766 275.179C202.942 275.342 203.115 275.513 203.282 275.69C203.457 275.87 203.627 276.058 203.79 276.253Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M212.569 163.086L210.133 203.755C210.133 203.755 202.012 226.529 212.569 226.529C223.127 226.529 216.63 202.128 216.63 202.128L226.375 162.273L212.569 163.086Z'
                                    fill='#FFB9B9'
                                />
                                <path
                                    d='M291.345 158.206L293.781 198.874C293.781 198.874 301.903 221.649 291.345 221.649C280.788 221.649 287.285 197.248 287.285 197.248L277.539 157.392L291.345 158.206Z'
                                    fill='#FFB9B9'
                                />
                                <path
                                    d='M242.618 330.641L244.242 350.975C244.242 350.975 237.745 367.243 248.302 368.869C248.408 368.886 248.513 368.901 248.616 368.916C249.925 369.096 251.258 368.964 252.506 368.529C253.754 368.095 254.882 367.371 255.798 366.417C256.713 365.463 257.39 364.305 257.774 363.039C258.157 361.772 258.236 360.433 258.004 359.13L256.424 350.162C256.424 350.162 257.236 333.894 252.363 330.641C247.49 327.387 242.618 330.641 242.618 330.641Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M258.048 312.747C258.048 312.747 256.424 317.627 257.236 322.507C258.048 327.387 258.86 341.215 258.86 341.215C258.86 341.215 258.86 355.855 264.545 355.855C266.576 355.855 268.295 354.092 269.667 351.824C271.086 349.419 272.003 346.75 272.363 343.98C272.722 341.21 272.516 338.395 271.757 335.707L268.606 324.134V315.187L258.048 312.747Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M228 163.899C228 163.899 210.133 187.487 219.878 222.462C229.624 257.437 230.436 267.198 230.436 267.198L236.121 333.894C236.121 333.894 245.866 337.148 253.988 332.268L251.551 268.011V228.969L256.424 272.078V315.187C256.424 315.187 267.794 326.574 275.915 316L277.539 268.011C277.539 268.011 282.412 201.315 279.163 194.808C275.915 188.301 268.606 165.526 268.606 165.526L228 163.899Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M244.173 54.1532C244.173 54.1532 235.914 51.199 230.605 63.0159C225.295 74.8329 217.036 87.8315 217.036 87.8315L221.756 89.0132C221.756 89.0132 222.936 80.7413 225.885 79.5596L224.705 90.1948C224.705 90.1948 260.102 102.603 276.03 89.0132L275.44 84.8772C275.44 84.8772 277.8 85.4681 277.8 89.0132L279.57 87.2406C279.57 87.2406 277.8 83.6955 272.49 78.9688C269.005 75.8663 267.808 69.964 267.396 66.2737C267.088 63.1923 265.857 60.2762 263.865 57.9077C260.336 53.8122 253.907 49.4593 244.173 54.1532Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M248.303 93.1359C256.825 93.1359 263.733 86.2169 263.733 77.6819C263.733 69.1468 256.825 62.2278 248.303 62.2278C239.781 62.2278 232.873 69.1468 232.873 77.6819C232.873 86.2169 239.781 93.1359 248.303 93.1359Z'
                                    fill='#FFB9B9'
                                />
                                <path
                                    d='M244.242 87.4424L239.37 106.963L253.988 149.259L265.358 104.523C265.358 104.523 254.8 97.2029 257.236 87.4424L244.242 87.4424Z'
                                    fill='#FFB9B9'
                                />
                                <path
                                    d='M284.036 198.874C284.036 198.874 260.484 207.822 255.612 193.994C250.739 180.167 251.551 169.593 251.551 169.593C251.551 169.593 244.242 198.061 232.06 198.061C219.878 198.061 213.381 193.994 213.381 193.994C213.381 193.994 222.315 165.526 230.436 160.646C230.436 160.646 230.477 160.548 230.55 160.369C231.004 159.231 232.612 154.716 231.484 150.747C231.254 149.931 230.901 149.154 230.436 148.445C227.187 143.565 223.939 141.938 224.751 129.738L218.254 106.963L240.661 101.79L252.363 138.685L263.018 102.563L282.412 105.337C282.412 105.337 284.036 134.618 275.915 145.192C274.638 146.857 273.67 148.739 273.056 150.747C271.643 155.237 271.976 159.963 272.739 163.737H272.747C273.257 166.31 274.047 168.819 275.103 171.22L284.036 198.874Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M277.539 106.15L282.412 105.336C282.412 105.336 284.442 104.93 288.097 107.777C292.764 111.412 292.969 160.646 292.969 160.646L277.539 161.459V106.15Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M222.315 108.59L218.254 106.963C218.254 106.963 216.63 106.15 215.818 111.03C215.005 115.91 210.945 166.339 210.945 166.339L227.999 165.526V128.924L222.315 108.59Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M230.455 79.9318C230.455 79.9318 235.175 73.4324 239.304 72.2508C243.434 71.0691 244.024 65.1606 244.024 65.1606C244.024 65.1606 254.643 76.9775 261.132 77.5684C267.621 78.1592 261.722 63.3881 261.722 63.3881L249.923 60.4338L238.714 61.6155L229.865 67.524L230.455 79.9318Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M252.769 160.239C255.46 160.239 257.642 158.054 257.642 155.359C257.642 152.664 255.46 150.479 252.769 150.479C250.078 150.479 247.896 152.664 247.896 155.359C247.896 158.054 250.078 160.239 252.769 160.239Z'
                                    fill='#2F2E41'
                                />
                                <path
                                    d='M336.543 86.1061C332.336 74.2392 332.515 61.2556 337.047 49.5095C341.579 37.7635 350.163 28.0329 361.244 22.0823C372.325 16.1318 385.168 14.3553 397.444 17.075C409.721 19.7947 420.618 26.8306 428.159 36.9066C435.7 46.9827 439.386 59.4318 438.548 71.9963C437.711 84.5608 432.406 96.4088 423.594 105.392C414.783 114.374 403.049 119.897 390.521 120.959C377.992 122.02 365.499 118.55 355.306 111.177C353.899 113.615 351.909 115.666 349.516 117.145C347.123 118.625 344.402 119.487 341.594 119.654C338.787 119.822 335.982 119.29 333.431 118.105C330.88 116.921 328.661 115.121 326.975 112.868C325.288 110.614 324.186 107.977 323.766 105.192C323.347 102.407 323.624 99.561 324.572 96.9094C325.52 94.2578 327.109 91.883 329.199 89.9977C331.288 88.1123 333.811 86.7752 336.543 86.1061Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M366.316 72.4173C369.676 72.4173 372.4 69.689 372.4 66.3236C372.4 62.9581 369.676 60.2299 366.316 60.2299C362.956 60.2299 360.231 62.9581 360.231 66.3236C360.231 69.689 362.956 72.4173 366.316 72.4173Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M387.074 72.4173C390.434 72.4173 393.158 69.689 393.158 66.3236C393.158 62.9581 390.434 60.2299 387.074 60.2299C383.714 60.2299 380.99 62.9581 380.99 66.3236C380.99 69.689 383.714 72.4173 387.074 72.4173Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M407.832 72.4173C411.193 72.4173 413.917 69.689 413.917 66.3236C413.917 62.9581 411.193 60.2299 407.832 60.2299C404.472 60.2299 401.748 62.9581 401.748 66.3236C401.748 69.689 404.472 72.4173 407.832 72.4173Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M331.342 109.066C327.445 108.065 323.987 105.803 321.507 102.631C319.026 99.4584 317.662 95.5542 317.625 91.5252C317.589 87.4961 318.883 83.5678 321.305 80.3511C323.728 77.1344 327.144 74.8095 331.022 73.7379C326.723 61.2326 327.024 47.6005 331.871 35.2978C336.718 22.9951 345.793 12.8307 357.461 6.63605C369.129 0.441377 382.621 -1.37627 395.509 1.5106C408.396 4.39746 419.83 11.799 427.751 22.3817C435.672 32.9644 439.558 46.0323 438.711 59.231C437.863 72.4297 432.337 84.8913 423.128 94.3704C413.918 103.85 401.632 109.723 388.481 110.933C375.331 112.142 362.182 108.608 351.403 100.967C349.351 104.276 346.301 106.845 342.694 108.301C339.087 109.757 335.111 110.025 331.342 109.066ZM397.038 2.69104C387.455 0.230686 377.384 0.427518 367.903 3.26045C358.423 6.09339 349.89 11.4557 343.22 18.7732C336.549 26.0906 331.991 35.0875 330.035 44.8004C328.078 54.5133 328.797 64.5762 332.113 73.9116L332.293 74.4187L331.771 74.5472C328.995 75.2269 326.431 76.5853 324.308 78.5009C322.185 80.4166 320.57 82.8296 319.606 85.5239C318.643 88.2183 318.361 91.1099 318.787 93.9398C319.213 96.7698 320.334 99.4498 322.047 101.74C323.761 104.03 326.015 105.859 328.608 107.062C331.2 108.266 334.051 108.807 336.903 108.636C339.756 108.466 342.521 107.59 344.953 106.087C347.384 104.583 349.406 102.499 350.836 100.022L351.105 99.5549L351.541 99.8706C358.043 104.577 365.502 107.788 373.386 109.276C381.27 110.763 389.384 110.491 397.15 108.477C404.917 106.464 412.144 102.759 418.317 97.6273C424.49 92.4954 429.457 86.0628 432.863 78.788C436.268 71.5133 438.03 63.5755 438.021 55.5407C438.013 47.5059 436.235 39.5719 432.813 32.3044C429.392 25.0368 424.412 18.6148 418.228 13.496C412.044 8.37732 404.809 4.68797 397.038 2.69104Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M57.356 326.111C57.2446 325.928 54.6177 321.535 53.707 312.413C52.8716 304.044 53.4088 289.937 60.7135 270.259C74.5522 232.981 57.5243 202.903 57.3503 202.603L58.1904 202.115C58.2345 202.191 62.6284 209.86 65.224 222.071C68.6536 238.261 67.4048 255.094 61.6238 270.598C47.8088 307.813 58.0795 325.43 58.1847 325.604L57.356 326.111Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M51.945 200.9C50.6007 200.9 49.2867 200.501 48.169 199.753C47.0513 199.005 46.1802 197.942 45.6658 196.698C45.1514 195.454 45.0168 194.086 45.279 192.765C45.5413 191.445 46.1886 190.232 47.1391 189.28C48.0896 188.328 49.3006 187.68 50.619 187.417C51.9374 187.154 53.304 187.289 54.5459 187.804C55.7878 188.32 56.8493 189.192 57.5961 190.311C58.3429 191.431 58.7415 192.747 58.7415 194.093C58.7395 195.898 58.0228 197.628 56.7486 198.904C55.4745 200.18 53.7469 200.898 51.945 200.9ZM51.945 188.259C50.7928 188.259 49.6665 188.601 48.7084 189.242C47.7504 189.883 47.0037 190.794 46.5628 191.86C46.1219 192.927 46.0065 194.1 46.2313 195.232C46.4561 196.363 47.0109 197.403 47.8257 198.219C48.6404 199.035 49.6784 199.591 50.8085 199.816C51.9385 200.041 53.1098 199.925 54.1743 199.484C55.2388 199.042 56.1487 198.294 56.7888 197.335C57.4289 196.375 57.7706 195.247 57.7706 194.093C57.7688 192.546 57.1545 191.063 56.0623 189.97C54.9702 188.876 53.4895 188.26 51.945 188.259Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M71.8492 223.752C75.3347 223.752 78.1602 220.922 78.1602 217.432C78.1602 213.941 75.3347 211.111 71.8492 211.111C68.3636 211.111 65.5381 213.941 65.5381 217.432C65.5381 220.922 68.3636 223.752 71.8492 223.752Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M58.256 239.797C56.9118 239.797 55.5977 239.398 54.4801 238.65C53.3624 237.902 52.4912 236.839 51.9768 235.595C51.4624 234.351 51.3278 232.983 51.5901 231.662C51.8523 230.342 52.4996 229.129 53.4501 228.177C54.4006 227.225 55.6117 226.577 56.9301 226.314C58.2485 226.051 59.615 226.186 60.8569 226.702C62.0988 227.217 63.1603 228.089 63.9071 229.209C64.6539 230.328 65.0525 231.644 65.0525 232.99C65.0505 234.795 64.3338 236.525 63.0597 237.801C61.7855 239.078 60.0579 239.795 58.256 239.797ZM58.256 227.156C57.1038 227.156 55.9775 227.498 55.0195 228.139C54.0615 228.78 53.3148 229.691 52.8739 230.758C52.4329 231.824 52.3176 232.997 52.5424 234.129C52.7671 235.26 53.322 236.3 54.1367 237.116C54.9514 237.932 55.9894 238.488 57.1195 238.713C58.2495 238.938 59.4209 238.822 60.4854 238.381C61.5499 237.939 62.4597 237.191 63.0998 236.232C63.7399 235.272 64.0816 234.144 64.0816 232.99C64.0799 231.443 63.4656 229.96 62.3734 228.867C61.2813 227.773 59.8005 227.158 58.256 227.156Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M74.7619 252.925C73.4176 252.925 72.1036 252.526 70.9859 251.778C69.8682 251.03 68.9971 249.967 68.4827 248.723C67.9683 247.479 67.8337 246.111 68.0959 244.79C68.3582 243.47 69.0055 242.257 69.956 241.305C70.9065 240.353 72.1175 239.705 73.4359 239.442C74.7543 239.179 76.1209 239.314 77.3628 239.829C78.6047 240.345 79.6662 241.217 80.413 242.336C81.1598 243.456 81.5584 244.772 81.5584 246.118C81.5564 247.923 80.8397 249.653 79.5655 250.929C78.2913 252.205 76.5638 252.923 74.7619 252.925ZM74.7619 240.284C73.6097 240.284 72.4834 240.626 71.5253 241.267C70.5673 241.908 69.8206 242.819 69.3797 243.885C68.9388 244.951 68.8234 246.125 69.0482 247.256C69.273 248.388 69.8278 249.428 70.6425 250.244C71.4573 251.06 72.4953 251.615 73.6253 251.841C74.7554 252.066 75.9267 251.95 76.9912 251.509C78.0557 251.067 78.9655 250.319 79.6057 249.36C80.2458 248.4 80.5875 247.272 80.5875 246.118C80.5857 244.571 79.9714 243.088 78.8793 241.994C77.7871 240.901 76.3064 240.285 74.7619 240.284Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M313.126 303.977C316.612 303.977 319.437 301.148 319.437 297.657C319.437 294.166 316.612 291.336 313.126 291.336C309.641 291.336 306.815 294.166 306.815 297.657C306.815 301.148 309.641 303.977 313.126 303.977Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M311.184 301.06C309.84 301.06 308.526 300.661 307.408 299.913C306.291 299.165 305.419 298.102 304.905 296.858C304.391 295.614 304.256 294.246 304.518 292.925C304.781 291.605 305.428 290.392 306.378 289.44C307.329 288.488 308.54 287.84 309.858 287.577C311.177 287.314 312.543 287.449 313.785 287.964C315.027 288.48 316.089 289.352 316.835 290.472C317.582 291.591 317.981 292.907 317.981 294.253C317.979 296.058 317.262 297.788 315.988 299.064C314.714 300.34 312.986 301.058 311.184 301.06ZM311.184 288.419C310.032 288.419 308.906 288.761 307.948 289.402C306.99 290.043 306.243 290.954 305.802 292.02C305.361 293.087 305.246 294.26 305.471 295.392C305.695 296.523 306.25 297.563 307.065 298.379C307.88 299.195 308.918 299.751 310.048 299.976C311.178 300.201 312.349 300.085 313.414 299.644C314.478 299.202 315.388 298.454 316.028 297.495C316.668 296.535 317.01 295.407 317.01 294.253C317.008 292.706 316.394 291.223 315.302 290.13C314.21 289.036 312.729 288.42 311.184 288.419Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M133.018 339.471C136.504 339.471 139.329 336.641 139.329 333.15C139.329 329.66 136.504 326.83 133.018 326.83C129.533 326.83 126.707 329.66 126.707 333.15C126.707 336.641 129.533 339.471 133.018 339.471Z'
                                    className={"splashBlue"}
                                />
                                <path
                                    d='M131.076 336.554C129.732 336.554 128.418 336.155 127.3 335.407C126.183 334.659 125.312 333.596 124.797 332.352C124.283 331.108 124.148 329.739 124.41 328.419C124.673 327.098 125.32 325.886 126.27 324.934C127.221 323.982 128.432 323.333 129.75 323.071C131.069 322.808 132.435 322.943 133.677 323.458C134.919 323.973 135.981 324.846 136.727 325.965C137.474 327.084 137.873 328.401 137.873 329.747C137.871 331.552 137.154 333.282 135.88 334.558C134.606 335.834 132.878 336.552 131.076 336.554ZM131.076 323.912C129.924 323.912 128.798 324.254 127.84 324.896C126.882 325.537 126.135 326.448 125.694 327.514C125.253 328.58 125.138 329.753 125.363 330.885C125.587 332.017 126.142 333.057 126.957 333.873C127.772 334.689 128.81 335.244 129.94 335.469C131.07 335.694 132.241 335.579 133.306 335.137C134.37 334.696 135.28 333.948 135.92 332.988C136.56 332.029 136.902 330.901 136.902 329.747C136.9 328.2 136.286 326.717 135.194 325.623C134.102 324.529 132.621 323.914 131.076 323.912Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M53.4014 272.86C56.8869 272.86 59.7125 270.03 59.7125 266.539C59.7125 263.048 56.8869 260.218 53.4014 260.218C49.9159 260.218 47.0903 263.048 47.0903 266.539C47.0903 270.03 49.9159 272.86 53.4014 272.86Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M61.169 326.343C61.169 326.343 54.8579 310.785 73.7911 299.115L61.169 326.343Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M53.407 326.061C53.407 326.061 50.5347 309.516 28.3042 309.657L53.407 326.061Z'
                                    fill='#CFCCE0'
                                />
                                <path
                                    d='M101.948 325.371H0V326.343H101.948V325.371Z'
                                    fill='#CFCCE0'
                                />
                            </svg>
                        </header>
                    </div>
                    <div className='faqs'>
                        {faqs.map((faq, i) => (
                            <Accordion key={i} faq={faq} index={i} toggleFAQ={toggleFaq} />
                        ))}
                    </div>
                    <p className='problems'>
                        {t("otherProblems")}
                        <a href='/Contact'>feedback</a>
                    </p>
                </section>
            </div>
        </motion.div>
    );
};

export default Help;
