import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Button = ({ textInput }) => {
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  const { t } = useTranslation();

  return (
    <>
      <button
        type={"submit"}
        onClick={ToggleClass}
        className={isActive ? "btn animating remove" : "btn"}
        data-btn
      >
        {textInput}
        {/* {t("contactSend")} */}
      </button>
      <div className="checkmark-container">
        <svg
          x="0px"
          y="0px"
          fill="none"
          className="checkmark-svg"
          viewBox="0 0 25 30"
        >
          <path d="M2,19.2C5.9,23.6,9.4,28,9.4,28L23,2" />
        </svg>
      </div>
    </>
  );
};

export default Button;
