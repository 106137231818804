import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cookie from "../assets/img/Cookies.png";
import xIcon from "../assets/img/x-icon.svg";
import securityIcon from "../assets/img/security.svg";
const Cookie = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState({ display: "content" });

  const handleCookie = () => {
    setIsOpen({ display: "none" });
    console.log(JSON.stringify(isOpen));
  };

  return (
    <>
      <section style={isOpen} className="cookie">
        <a className="x-icon" href="/">
          <svg
            onClick={handleCookie}
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <rect
              x="1"
              y="2.12109"
              width="3"
              height="20"
              rx="1.5"
              transform="rotate(-45 1 2.12109)"
              fill="#C4C4C4"
            />
            <rect
              x="14.7739"
              y="0.131348"
              width="3"
              height="20"
              rx="1.5"
              transform="rotate(45 14.7739 0.131348)"
              fill="#C4C4C4"
            />
          </svg>
        </a>
        <div className="content-cookie">
          <img src={cookie} alt="Cookie icon" />
          <h1>Politika privatnosti i kolačići</h1>
        </div>
        <p>
          We use cookies on our website to provide the best user experience
          possible.
        </p>
        <button onClick={handleCookie} className="agreeBtn">
          Accept
        </button>
      </section>
    </>
  );
};

export default Cookie;
