import React, { useState, useEffect, Suspense } from 'react';
// CSS
import './App.css';
// Pages
import Home from "./pages/Home";
import FindBus from "./pages/FindBus";
import Help from "./pages/Help";
import Contact from "./pages/Contact";
import Loading from "./components/Loading";
import Settings from './pages/Settings';
import SplashScreen from './pages/SplashScreen';
// Router
import { Route, Switch, useLocation } from "react-router-dom";
// Framer Motion
import { AnimatePresence } from "framer-motion";
// Change language
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import LocaleContext from "./LocaleContext";
import useVH from "react-viewport-height"
function App() {
    useVH();
    const [locale, setLocale] = useState(i18n.language)
    i18n.on('languageChanged', (lng) => setLocale(i18n.language));
    const location = useLocation()
    const [theme, settheme] = useState();
    const { t } = useTranslation();
    useEffect(() => {
        const x = localStorage.getItem("theme")
        if (x == null) {
            localStorage.setItem("theme", 'light')
        }
        settheme(localStorage.getItem("theme"))
    }, []);

    useEffect(() => {
        const firstLoad = localStorage.getItem("firstLoad")
        if(!firstLoad) {
            window.location.href="/Splash"
            localStorage.setItem("firstLoad", false)
        }
    }, [])
    

    return (
        <AnimatePresence >
            <LocaleContext.Provider value={{ locale, setLocale }}>
                <Suspense fallback={<Loading />}>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/"> <Home theme={theme} /> </Route>
                        <Route exact path={"/FindBus"}> <FindBus theme={theme} /></Route>
                        <Route exact path="/Help"><Help theme={theme} /> </Route>
                        <Route exact path="/Contact"><Contact theme={theme} t={t} /></Route>
                        <Route exact path="/Settings"> <Settings theme={theme} settheme={settheme} /> </Route>
                        <Route exact path="/Splash"><SplashScreen theme={theme} /></Route>
                    </Switch>
                </Suspense>
            </LocaleContext.Provider>
        </AnimatePresence>
    );
}

export default App;
