import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import DownSheet from "../components/DownSheet";
import Expand from "../components/Expand";
import Dashboard from "../components/Dashboard";
import Map from "../back/Map";
import locationIcon from "../assets/img/locationIcon.png"
import db from "../back/firebase.js";
import {IconLayer} from '@deck.gl/layers'
import { collection, getDocs } from "firebase/firestore";
import { orderByDistance, getCenterOfBounds, getDistance, getPathLength } from "geolib";
import UpLocate from "../components/UpLocate";

// Cathcbus backend
const FindBus = ({theme}) => {

  //Niz busevi u koji se postavljaju svi busevi iz baze podataka
  const [busevi, setBusevi] = useState([]);
  //Niz stanice u koji se postavljaju sve stanice iz baze podataka
  const [stanice, setStanice] = useState([]);

  //Lokacije koje korisnik unosi
  const [pocetnaLokacija, setPocetnaLokacija] = useState();
  const [odredisnaLokacija, setOdredisnaLokacija] = useState();
  //Varijable koje pohranjuju informacije vezano za lociranje korisnika
  const [korisnikLociran, setKorisnikLociran] = useState(false)
  const [lokacijaKorisnika, setLokacijaKorisnika] = useState();

  //StanicaA - stanica na kojoj korisnik ulazi u autobus
  const [stanicaA, setStanicaA] = useState();
  //StanicaB - stanica na kojoj korisnik izlazi iz autobusa
  const [stanicaB, setStanicaB] = useState();

  //Niz sortiranih stanica prema udaljenosti od pocetne lokacije
  const [sortiraneStaniceA, setSortiraneStaniceA] = useState();

  //Niz sortiranih stanica prema udeljsnosti od odredisne lokacije
  const [sortiraneStaniceB, setSortiraneStaniceB] = useState();

  //Nizovi koordinata za prikaz navigacije na karti

  const [drivingLayer, setDrivingLayer] = useState()
  const [walkingLayer, setWalkingLayer] = useState()
  const [locationsLayer, setLocationsLayer] = useState()

  //Autobus koji ce se koristiti za generiranje rute (jedan od autobusa iz niza 'odgovarajuciBusevi')
  const [pronadeniBus, setPronadeniBus] = useState();
  //Niz autobusa kojima korisnik moze stici do odredista
  const [odgovarajuciBusevi, setOdgovarajuciBusevi] = useState([]);

  //Varijable koje sluze za stilski dio, otvaranje i zatvaranje pojedinih komponenti
  const [isExpandOpen, setIsExpandOpen] = useState(true);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isUpLocateOpen, setIsUpLocateOpen] = useState(false);
  const [isFirstInputActive, setIsFirstInputActive] = useState(true);

  //Varijable u kojima se pohranjuju adrese pocetne i odredisne lokacije
  const [inputPocetnaAdresa, setInputPocetnaAdresa] = useState('');
  const [inputOdredisnaAdresa, setInputOdredisnaAdresa] = useState('');

  //Varijabla koja predstavlja duzinuPuta te se postavlja u Map.jsx komponenti uz pomoc API-a
  const [duzinaPuta, setDuzinaPuta] = useState()

  //Dashboard info (number of buses...)

  //Viewport - jedna od stavki koja je potrebna za prikaz mape te odreduje pocetnu lokaciju koju ce mapa prikazivati nakon prvog ucitavanja
  const [viewport, setViewport] = useState({
    latitude: 43.51149720165839,
    longitude: 16.444448199834238,
    bearing: 0,
    pitch: 0,

    width: "100%",
    height: "100%",
    zoom: 13,
  });

  //Dohvacanje podataka iz baze podataka
  useEffect(() => {
    const fetchData = async () => {
      const dataBusevi = await getDocs(collection(db, "busevi"));
      setBusevi(dataBusevi.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      const dataStanice = await getDocs(collection(db, "stanice"));
      setStanice(dataStanice.docs.map((doc) => ({ ...doc.data() })));
    };
    fetchData();

    //Zahtjev za lociranje korisnika
    const locirajKorisnika = () => {
      navigator.geolocation.getCurrentPosition(position => {
        setKorisnikLociran(true)
        setLokacijaKorisnika({ longitude: position.coords.longitude, latitude: position.coords.latitude })
      })
    }
    locirajKorisnika();
    console.log('Loaded')
  }, []);

  //Promjena viewporta u trenutku izmjene jedne od lokacija
  useEffect(() => {
    if (pocetnaLokacija) {
      if (odredisnaLokacija) {
        pronadiNajblizeStanice();
        setIsDashboardOpen(false)
        const x = getCenterOfBounds([
          {
            latitude: pocetnaLokacija.latitude,
            longitude: pocetnaLokacija.longitude
          },
          {
            latitude: odredisnaLokacija.latitude,
            longitude: odredisnaLokacija.longitude
          }
        ])
        setViewport({...viewport, ...x, zoom: 13})
        
        const ICON_MAPPING = {
          marker: {x: 0, y: 0, width: 27, height: 41, anchorY: 35, mask: false}
        };
        const locationsData = [{position: [pocetnaLokacija.longitude, pocetnaLokacija.latitude]}, {position: [odredisnaLokacija.longitude, odredisnaLokacija.latitude]}]
        const layer = new IconLayer({
          id: 'icon-layer',
          data: locationsData,
          pickable: false,
          iconAtlas: locationIcon,
          iconMapping: ICON_MAPPING,
          getIcon: d => 'marker',
          getColor: d => [181, 181, 181],
          sizeScale: 8,
          getPosition: d => d.position,
          getSize: d => 6,
        });
        setLocationsLayer(layer)
      }
    }
  }, [pocetnaLokacija, odredisnaLokacija]);

  //Sortiranje stanica iz varijable 'stanice' u odnosu na udaljenost od pocetne i odredisne lokacije
  //Postavlja se vrijednost varijabli 'sortiraneStaniceA' i 'sortiraneStaniceB'
  const pronadiNajblizeStanice = () => {
    const x = orderByDistance(
      { ...pocetnaLokacija }, //Users nearest station
      stanice.map((stanica) => ({
        latitude: stanica.lokacija.latitude,
        longitude: stanica.lokacija.longitude,
      }))
    );

    const xImena = x.map(xLokacija => stanice.find(stanica => JSON.stringify(stanica.lokacija) === JSON.stringify(xLokacija)))
    setSortiraneStaniceA(xImena);

    const y = orderByDistance(
      { ...odredisnaLokacija }, //Selected place nearest station
      stanice.map((stanica) => ({
        latitude: stanica.lokacija.latitude,
        longitude: stanica.lokacija.longitude,
      }))
    );
    const yImena = y.map(yLokacija => stanice.find(stanica => JSON.stringify(stanica.lokacija) === JSON.stringify(yLokacija)))
    setSortiraneStaniceB(yImena);
  };

  useEffect(() => {
    if (sortiraneStaniceA && sortiraneStaniceB) {
      pronadiOdgovarajuceStanice()
    }
  }, [sortiraneStaniceA, sortiraneStaniceB]);

  const pronadiOdgovarajuceStanice = () => {
    let shouldbreak = false;
    let shouldContinue = true;
    let higher = 0;
    let lower = 0;
    for (higher = 0; higher < stanice.length; higher++) {
      for (lower = 0; lower <= higher; lower++) {
        busevi.map(bus => {
          if (!shouldbreak) {
            if (
              bus.stanice.includes(sortiraneStaniceA[higher].ime) &&
              bus.stanice.includes(sortiraneStaniceB[lower].ime) &&
              (bus.stanice.indexOf(sortiraneStaniceA[higher].ime) < bus.stanice.indexOf(sortiraneStaniceB[lower].ime) || bus.stanice.indexOf(sortiraneStaniceA[higher].ime) < bus.stanice.indexOf(sortiraneStaniceB[lower].ime, 2))
            ) {
              shouldbreak = true;
              const mogucnost1 = provjeraMogucnosti(bus, sortiraneStaniceA[higher], sortiraneStaniceB[lower])
              const mogucnost2 = provjeraMogucnosti(bus, sortiraneStaniceA[higher + 1], sortiraneStaniceB[lower])
              const mogucnost3 = provjeraMogucnosti(bus, sortiraneStaniceA[higher], sortiraneStaniceB[lower + 1])
              const mogucnost4 = provjeraMogucnosti(bus, sortiraneStaniceA[higher + 1], sortiraneStaniceB[lower + 1])

              const mogucnosti = [mogucnost1, mogucnost2, mogucnost3, mogucnost4]

              const najmanjaUdaljenost = Math.min(...mogucnosti.map(mogucnost => mogucnost.udaljenost))
              const najboljaMogucnost = mogucnosti.find(mogucnost => mogucnost.udaljenost == najmanjaUdaljenost)


              shouldContinue = false;
              setStanicaA(najboljaMogucnost.stanicaA)
              setStanicaB(najboljaMogucnost.stanicaB)
              setPronadeniBus(bus)
            } else if (
              bus.stanice.includes(sortiraneStaniceA[lower].ime) &&
              bus.stanice.includes(sortiraneStaniceB[higher].ime) &&
              (bus.stanice.indexOf(sortiraneStaniceA[lower].ime) < bus.stanice.indexOf(sortiraneStaniceB[higher].ime) || bus.stanice.indexOf(sortiraneStaniceA[lower].ime) < bus.stanice.indexOf(sortiraneStaniceB[higher].ime, 2))
            ) {
              shouldbreak = true;
              const mogucnost1 = provjeraMogucnosti(bus, sortiraneStaniceA[lower], sortiraneStaniceB[higher])
              const mogucnost2 = provjeraMogucnosti(bus, sortiraneStaniceA[lower + 1], sortiraneStaniceB[higher])
              const mogucnost3 = provjeraMogucnosti(bus, sortiraneStaniceA[lower], sortiraneStaniceB[higher + 1])
              const mogucnost4 = provjeraMogucnosti(bus, sortiraneStaniceA[lower + 1], sortiraneStaniceB[higher + 1])

              const mogucnosti = [mogucnost1, mogucnost2, mogucnost3, mogucnost4]

              const najmanjaUdaljenost = Math.min(...mogucnosti.map(mogucnost => mogucnost.udaljenost))
              const najboljaMogucnost = mogucnosti.find(mogucnost => mogucnost.udaljenost == najmanjaUdaljenost)

              shouldContinue = false;
              setStanicaA(najboljaMogucnost.stanicaA)
              setStanicaB(najboljaMogucnost.stanicaB)
              setPronadeniBus(bus)
            }
          }
        })
        if (shouldbreak) break;
      }
      if (shouldbreak) break;
    }

    //Presjedanje !!!
    /*
    if (shouldContinue) {

      let rezultat=[];
      let brojac = 0;

      const x1 = busevi.filter(bus => JSON.stringify(bus.stanice).includes(sortiraneStaniceA[0].ime))
      const y1 = busevi.filter(bus => JSON.stringify(bus.stanice).includes(sortiraneStaniceB[0].ime))

      x1.map(bus1 => {
        bus1.stanice.map(stanica1 => {
          const stanica1L = stanice.find(
            (stanica) => stanica.ime === stanica1
          )
          y1.map(bus2 => {
            bus2.stanice.map(stanica2 => {
              const stanica2L = stanice.find(
                (stanica) => stanica.ime === stanica2
              )
              brojac++;
              try{
                const distance = getDistance(
                  {latitude: stanica1L.lokacija.latitude, longitude: stanica1L.lokacija.longitude},
                  {latitude: stanica2L.lokacija.latitude, longitude: stanica2L.lokacija.longitude}
                )

                const walking1 = getDistance(
                  {latitude: pocetnaLokacija.latitude, longitude: pocetnaLokacija.longitude},
                  {latitude: stanica1L.lokacija.latitude, longitude: stanica1L.lokacija.longitude}
                )

                const walkingBetween = distance;

                const walking2 = getDistance(
                  {latitude: stanica2L.lokacija.latitude, longitude: stanica2L.lokacija.longitude},
                  {latitude: odredisnaLokacija.latitude, longitude: odredisnaLokacija.longitude}
                )
                console.log(stanica1L.ime, stanica2L.ime)
                //Duzina voznje busoma
                
                const firstRouteLength = duzinaVoznje(bus1, sortiraneStaniceA[0], stanica1L)
                //console.log(firstRouteLength)
                //const secondRouteLength = duzinaVoznje(bus2, stanica2L, sortiraneStaniceB[0])

                rezultat = [...rezultat, {
                  bus1: bus1.broj + ' ' + bus1.ime,
                  bus2: bus2.broj + ' ' + bus2.ime,
                  prvaRuta: sortiraneStaniceA[0].ime + '-' + stanica1L.ime,
                  drugaRuta: stanica2L.ime + '-' + sortiraneStaniceB[0].ime,
                  udaljenost: distance,
                }]
              }
              catch{console.log('Crashed')}
            })
          })
        })
      })
      const minDist = Math.min(...rezultat.map(rezultat => rezultat.udaljenost))
      const najbPresjedanje = rezultat.find(rezultat => rezultat.udaljenost == minDist)
      console.log(najbPresjedanje)
      console.log(brojac)
    }
    */
  }

  //Dodatak algoritmu u kojem se provjerava potencijalna greska
  const provjeraMogucnosti = (bus, stanicaA, stanicaB) => {
    if (bus.stanice.includes(stanicaA.ime) && bus.stanice.includes(stanicaB.ime) && bus.stanice.indexOf(stanicaA.ime) < bus.stanice.indexOf(stanicaB.ime)) {
      const indexA = bus.stanice.indexOf(stanicaA.ime)
      const indexB = bus.stanice.indexOf(stanicaB.ime)
      const udaljenost = indexB - indexA;
      const returnObj = {
        stanicaA: stanicaA,
        stanicaB: stanicaB,
        udaljenost: udaljenost
      }
      return returnObj;
    } else if(bus.stanice.indexOf(stanicaA.ime) < bus.stanice.indexOf(stanicaB.ime, 2)) {
      const indexA = bus.stanice.indexOf(stanicaA.ime)
      const indexB = bus.stanice.indexOf(stanicaB.ime, 2)
      const udaljenost = indexB - indexA;
      const returnObj = {
        stanicaA: stanicaA,
        stanicaB: stanicaB,
        udaljenost: udaljenost
      }
      return returnObj;
    } else {
      const returnObj = {
        stanicaA: stanicaA,
        stanicaB: stanicaB,
        udaljenost: 500
      }
      return returnObj;
    }
  }

  /*
  const duzinaVoznje = (bus, prvaStanica, drugaStanica) => {
    let indexA, indexB;
    if(bus.stanice.indexOf(prvaStanica.ime) < bus.stanice.indexOf(drugaStanica.ime)) {
      indexA = bus.stanice.indexOf(prvaStanica.ime);
      indexB = bus.stanice.indexOf(drugaStanica.ime);
    } else {
      indexA = bus.stanice.indexOf(prvaStanica.ime);
      indexB = bus.stanice.indexOf(drugaStanica, 2);
    }

    const sliced = bus.stanice.slice(indexA, indexB + 1);

    const pathCoords = sliced.map(
      (stanica) => stanice.find(({ ime }) => stanica === ime)?.lokacija
    );

    const length = getPathLength([
      pathCoords.map(coord=>(
        {
          longitude: coord.longitude,
          latitude: coord.latitude
        }
      ))
    ])
    return length;

  }
  */

  //U trenutku promjene pocetne stanice ili odredisne stanice se poziva funkcija za pretragu odgovarajucih autobusa
  useEffect(() => {
    if (stanicaA && stanicaB) {
      getBus();
    }
  }, [stanicaA, stanicaB]);

  //Funkcija u kojoj se pretrazuju svi busevi koji u svom nizu buseva sadrzavaju ime pocetne stanice i ime odredisne stanice
  const getBus = () => {
    const x = busevi.filter(bus => JSON.stringify(bus.stanice).includes(stanicaA.ime))
    const reqBusevi = x.filter(bus => JSON.stringify(bus.stanice).includes(stanicaB.ime))

    setOdgovarajuciBusevi(reqBusevi);
  };

  return (
    <section className="findbus font-size-changer" data-theme={theme}>
      {!isUpLocateOpen ? 
      <Navbar 
      theme={theme}
      rightAlign={{ right: "auto" }}
      displayLogo={{ display: "none" }}
      hamColor={{ stroke: "#fff" }}
      backHam={{
        margin: "10px",
        borderRadius: "90%",
        backgroundColor: "var(--primarnaBoja)",
        height: "60px",
        width: "60px",
      }}
      hamStyle={{width: "60px", height: "60px"}}
      />
      : null
      }
      {isUpLocateOpen ? <UpLocate inputPocetnaAdresa={inputPocetnaAdresa} inputOdredisnaAdresa={inputOdredisnaAdresa} setInputPocetnaAdresa={setInputPocetnaAdresa} setInputOdredisnaAdresa={setInputOdredisnaAdresa} pocetnaLokacija={pocetnaLokacija} odredisnaLokacija={odredisnaLokacija} setPocetnaLokacija={setPocetnaLokacija} setOdredisnaLokacija={setOdredisnaLokacija} duzinaPuta={duzinaPuta} odgovarajuciBusevi={odgovarajuciBusevi} theme={theme} setIsFirstInputActive={setIsFirstInputActive} setIsDashboardOpen={setIsDashboardOpen} /> : null}
      <div className="app-test">
        <div className="map">
          <Map
            stanice={stanice}
            pronadeniBus={pronadeniBus}
            pocetnaLokacija={pocetnaLokacija}
            lokacijaKorisnika={lokacijaKorisnika}
            odredisnaLokacija={odredisnaLokacija}
            stanicaA={stanicaA}
            stanicaB={stanicaB}
            viewport={viewport}
            setViewport={setViewport}
            theme={theme}
            setDuzinaPuta={setDuzinaPuta}
            drivingLayer={drivingLayer}
            setDrivingLayer={setDrivingLayer}
            walkingLayer={walkingLayer}
            setWalkingLayer={setWalkingLayer}
            locationsLayer={locationsLayer}
            setLocationsLayer={setLocationsLayer}
          />
        </div>
      </div>
      {pronadeniBus ? <DownSheet theme={theme} odgovarajuciBusevi={odgovarajuciBusevi} /> : null}
      <Expand theme={theme} isExpandOpen={isExpandOpen} setIsDashboardOpen={setIsDashboardOpen} setIsExpandOpen={setIsExpandOpen} />
      <Dashboard theme={theme} isFirstInputActive={isFirstInputActive} setIsFirstInputActive={setIsFirstInputActive} setLocationsLayer={setLocationsLayer} setWalkingLayer={setWalkingLayer} setDrivingLayer={setDrivingLayer} inputPocetnaAdresa={inputPocetnaAdresa} setInputPocetnaAdresa={setInputPocetnaAdresa} inputOdredisnaAdresa={inputOdredisnaAdresa} setInputOdredisnaAdresa={setInputOdredisnaAdresa} setPocetnaLokacija={setPocetnaLokacija} setOdredisnaLokacija={setOdredisnaLokacija} isDashboardOpen={isDashboardOpen} setIsExpandOpen={setIsExpandOpen} setIsDashboardOpen={setIsDashboardOpen} setIsUpLocateOpen={setIsUpLocateOpen} lokacijaKorisnika={lokacijaKorisnika} />
      {/* dashboard za unos lokacija */}
      {/*  */}

    </section>
  );
};

export default FindBus;
